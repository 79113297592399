import SearchTextField from "../form/SearchTextField";
import { SearchAutocompleteField } from "../form/SearchAutocompleteField";
import SearchDatePicker from "../form/SearchDatePicker";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import SearchSelect from "../form/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";
import { fetchMue, resetMueResults, saveSearchValues, selectGeometry, toggleExpanded } from "../../stores/mue";
import { useNavigate } from "react-router-dom";
import { addSelectedFeatures } from "../../stores/map/feature";
import { setActiveFeatures } from "../../stores/map/map";

const mueDefault = {
    aktiNr: '',
    eraldiseNr: '',
    katastriNr: '',
    kinnistuNimi: '',
    kinnitamiseKpAlgusOpen:false,
    kinnitamiseKpLoppOpen:false,
    kvartaliNr: '',
    maakond: '',
    mkood: '',
    kinnitamiseKpMax: '',
    kinnitamiseKpMin: '',
    otsus: '',
    vald: '',
    vkood: '',
};

export default function MueForm () {
    const { counties, municipalities, baasandmedLiik} = useSelector(state => state.classifier);
    const { result, isLoading, searchValues, expandedResults } = useSelector(state => state.mue);
    const [county, setCounty] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, reset, control, formState: { errors, isSubmitSuccessful }, setValue, getValues, register } = useForm({
        defaultValues: (searchValues ?? mueDefault),
        resetOptions: {
            keepDirtyValues: true,
        },
    });

    const fetchResults = (data) => {
        dispatch(saveSearchValues(form.getValues()));
        dispatch(fetchMue(data));
    };

    const resetResults = () => {
        dispatch(resetMueResults());
    };
    
    const getSubTreeInfo = (results) => {
        return { items: results.mued.map(result => ({id: result.valitoo.id, nimi: result.nimi, geometry: (result.valitoo.alaGeoJson ? JSON.parse(result.valitoo.alaGeoJson) : null), aktiNr: result.aktiNr })), };
    };

    const showSelectedMue = (result) => {
        const selectedMue = result.map(r => ({ valitooId: r.id, nr: r.aktiNr, liik: 'MUE', geometry: r.geometry }));
        dispatch(addSelectedFeatures(selectedMue));
        dispatch(setActiveFeatures(selectedMue.map(t => (t.geometry))));

    };

    const openDetails = (id, geometry) => {
        dispatch(selectGeometry(geometry));
        navigate(`/mue/${id}`);
    };
    
    const toggleResults = (result) => {
        dispatch(toggleExpanded(result));
    };

    const form = { handleSubmit:handleSubmit, reset:reset, control:control, formState: { errors:errors, isSubmitSuccessful:isSubmitSuccessful }, setValue:setValue, defaultValues:mueDefault, getValues:getValues, register:register};

    const MueFields = ({setValue, control, errors, getValues}) => {          
        const filteredMunicipalities = municipalities.filter(muni => !!county? muni.maakondKood === county : getValues().mkood === muni.maakondKood); 
        const disabled = !filteredMunicipalities.length;

        return  <>
                    <SearchTextField control={control} name="katastriNr" label="Katastriüksus" pattern="cadastre" errors={errors}/>
                    <SearchTextField control={control} name='aktiNr' label='MUE nr'/>
                    <SearchAutocompleteField name="mkood" label="Maakond" options={counties} setValue={setValue} setCounty={setCounty} control={control}/>
                    <SearchAutocompleteField name="vkood" label="Vald" options={filteredMunicipalities} disabled={disabled} setValue={setValue} control={control}/>
                    <SearchTextField control={control} name="kinnistuNimi" label="Kinnistu nimi"/>
                    <SearchTextField control={control} name="kvartaliNr" label="Kvartali number"/>
                    <SearchTextField control={control} name="eraldiseNr" label="Eraldise number"/>
                    <SearchSelect control={control} name="otsus" placeholder="Uuenemise otsus" options={baasandmedLiik['MUE_OTSUS']} dataLabel="nimetus" defaultValueName='Vali uuenemise otsus' />
                    <Box sx={{display:'flex', width:'95%', my:1}}>
                        <Typography>Ekspertiisi kuupäev</Typography>
                    </Box>
                    <Box sx={{display:'flex', width:'95%', alignItems:"center"}}>
                        <SearchDatePicker control={control} name="kinnitamiseKpMin" setValue={setValue} placeholder="pp.kk.aaaa" toolbarTitle='Vali alguse kuupäev'/>
                        <div style={{margin: '8px'}}>–</div>
                        <SearchDatePicker control={control} name="kinnitamiseKpMax" setValue={setValue} placeholder="pp.kk.aaaa" toolbarTitle='Vali lõpu kuupäev'/>
                    </Box>
                </>
    };

    return  <SearchForm fields={MueFields({setValue:setValue, control:control, getValues:getValues, errors:errors })}
                handleSubmit={handleSubmit}
                result={result}
                isLoading={isLoading}
                form={form}
                fetchResults={fetchResults}
                resetResults={resetResults}
                getSubTreeInfo={getSubTreeInfo}
                openDetails={openDetails}
                showLocationForMain={() => false}
                showCadastre={() => true}
                showGeometries={showSelectedMue}
                expandedResults={expandedResults}
                toggleExpanded={toggleResults}
                />;
};