import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';
import StoreUtils from '../utils/StoreUtils';

// Slice
const slice = createSlice({
  name: 'classifier',
  initialState: {
    selectedType: null,
    baasandmed: {},
    baasandmedLiik: {},
    classifiers: [],
    searchValue: '',
    isLoading: false,
    error: false,
    counties: [],
    municipalities: [],
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    classifiersSuccess: (state, action) => {
      const loadedData = action.payload;
      for (var i = loadedData.length - 1; i >= 0; i--) {
          var item = loadedData[i];
          if (!state.baasandmed[item.liik]) {
              state.baasandmed[item.liik] = {};
          }
          state.baasandmed[item.liik][item.kood] = item.nimetus;

          if (!state.baasandmedLiik[item.liik]) {
            state.baasandmedLiik[item.liik] = [];
          }
          state.baasandmedLiik[item.liik].push(item);
      }

      state.isLoading = false;
    },
    countiesSuccess: (state, action) => {
      state.counties = action.payload;
      state.isLoading = false;
    },
    municipalitiesSuccess: (state, action) => {
      state.municipalities = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const { classifiersSuccess, startLoading, hasError, countiesSuccess, municipalitiesSuccess} = slice.actions;

export const fetchClassifiers = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('baas/').then((response) => {
      dispatch(classifiersSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchCounties = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('/ehak/maakonnad').then((response) => dispatch(countiesSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};
export const fetchMunicipalities = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('/ehak/valladLinnad').then((response) => dispatch(municipalitiesSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};