import { isNumber } from "lodash";

const GeneralUtils = {
  sortArrayByNumber(array, field, desc) {
    if (!array || !field) {
      return [];
    }
    let order = 1;
    if (!!desc) {
      order = -1;
    }
    return array.slice().sort((a, b) => (a[field] > b[field]) ? order : order * -1);
  },
  formatNumber(value) {
    return isNumber(value) ? value.toLocaleString('et-EE') : value;
  },
  formatNumberValue(nr) {
    if(nr === undefined){
      return null;
    }
    return Math.round(nr);
  },
  convertParamsToUrl(params) {
    const searchParams = [];

    for (let key in params) {
        if (params[key] && params[key] !== '') {
            searchParams.push(key + "=" + params[key]);
        }
      };

    return searchParams.join("&");
  },
  getFieldWithUnit(value, unit) {
    if (value) {
        return <>{value} {unit}</>;
    }
    return "";
  },
  sumArrayofValues(array) {
    if (!array) {
      return;
    }
    let numbers = array.filter(number => typeof number === 'number');
    return Math.round(numbers.reduce((a, b) => a + b, 0));
  }
};

export default GeneralUtils;