import {createSlice} from "@reduxjs/toolkit";
import {api} from "../api";
import StoreUtils from "../utils/StoreUtils";
import GeneralUtils from "../utils/GeneralUtils";
import { addSelectedFeatures } from "./map/feature";
import { setActiveFeatures } from "./map/map";

const apiPath = "teatis";

const slice = createSlice({
    name: 'teatis',
    initialState: {
        criteria:{},
        result:[],
        selectedResult: null,
        selectedGeometry: null,
        detailsOpen: false,
        isLoading:false,
        error: false,
        searchValues: null,
        expandedResults: []
    },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        notificationSuccess: (state, action) => {
            state.result = action.payload;
            state.expandedResults = [];
            state.isLoading = false;
        },
        detailSuccess: (state, action) => {
            state.selectedResult = action.payload;
            state.isLoading = false;
            state.detailsOpen = true;
        },    
        geometrySuccess: (state, action) => {
            state.selectedGeometry = action.payload;
        },    
        teatisDetailsVisibilityChanged: (state, action) => {
            state.detailsOpen = (action.payload !== undefined ? action.payload : !state.detailsOpen);
        },
        storeSearchValues: (state, action) => {
            state.searchValues = action.payload;
        },
        toggleExpandedResults: (state, action) => {
            const result = action.payload;
            state.expandedResults.indexOf(result) > -1 ? state.expandedResults.splice(result, 1) : state.expandedResults.push(result);
        }    
    },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, notificationSuccess, detailSuccess, geometrySuccess, teatisDetailsVisibilityChanged, storeSearchValues, toggleExpandedResults } = slice.actions;

export const toggleTeatisDetails = (open) => async dispatch => {
    dispatch(teatisDetailsVisibilityChanged(open));
};

export const fetchTeatised = (params) => async dispatch => {
    dispatch(startLoading());
    const criteriaParam = GeneralUtils.convertParamsToUrl(params);
    try {
        await api.get(`${apiPath}/puu?${criteriaParam}`).then((response) => {
            dispatch(notificationSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const resetTeatisedResults = () => async dispatch => {
    dispatch(notificationSuccess([]));
    dispatch(storeSearchValues(null));
};


export const fetchTeatisDetails = (teatisId) => async dispatch => {
    dispatch(startLoading());
    try {
        await api.get(`${apiPath}/teatisVaatamine/${teatisId}`).then((response) => {
            dispatch(detailSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const fetchTeatisGeometry = (teatisId) => async dispatch => {
    try {
        await api.get(`${apiPath}/selected?id=${teatisId}`).then((response) => {
            dispatch(geometrySuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const saveSearchValues = (values) => async dispatch => {
    dispatch(storeSearchValues(values));
};

export const fetchSelectedTeatised = (ids, single) => async dispatch => {
    const params = single ? (["id=" + ids]) : ids.map(id => ("idList=" + id));
    try {
        await api.get(`${apiPath}/selected?${params.join('&')}`).then((response) => {
            const selectedTeatised = response.data?.map(r => ({teatisId: r.id, teatiseNr: r.teatiseNr, tooKood: r.tooKood, geometry:JSON.parse(r.pindGeoJson) ?? null }));
            dispatch(addSelectedFeatures(selectedTeatised));
            dispatch(setActiveFeatures(selectedTeatised.map(t => (t.geometry))));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};


export const toggleExpanded = (result) => async dispatch => {
    dispatch(toggleExpandedResults(result));
};
