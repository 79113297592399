import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';

export function CenteredLoadingIndicator() {
  return <Grid container><LoadingIndicator /></Grid>;
}

export default function LoadingIndicator() {
  return <Box margin="12px" paddingBottom={20}>
    <CircularProgress color="secondary" size={80} thickness={7} />
  </Box>;
}