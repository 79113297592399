import {createSlice} from "@reduxjs/toolkit";
import {api} from "../api";
import StoreUtils from "../utils/StoreUtils";
import { addSelectedFeatures } from "./map/feature";
import { setActiveFeatures } from "./map/map";

const apiPath = "mke";

const slice = createSlice({
    name: 'mke',
    initialState: {
        result: [],
        selectedResult: null,
        detailsOpen: false,
        isLoading: false,
        error: false,
        searchValues: null,
        expandedResults: [],
        selectedMkeId: null
    },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        dataSuccess: (state, action) => {
            state.result = action.payload;
            state.expandedResults = [];
            state.isLoading = false;
        },
        detailSuccess: (state, action) => {
            state.selectedResult = action.payload;
            state.isLoading = false;
            state.detailsOpen = true;
        },
        mkeDetailsVisibilityChanged: (state, action) => {
            state.detailsOpen = (action.payload !== undefined ? action.payload : !state.detailsOpen);
        },
        storeSearchValues: (state, action) => {
            state.searchValues = action.payload;
        },
        toggleExpandedResults: (state, action) => {
            const result = action.payload;
            state.expandedResults.indexOf(result) > -1 ? state.expandedResults.splice(result, 1) : state.expandedResults.push(result);
        },
        selectedMkeSuccess: (state, action) => {
            state.selectedMkeId = action.payload;
        }
    },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, dataSuccess, detailSuccess, mkeDetailsVisibilityChanged, storeSearchValues, toggleExpandedResults, selectedMkeSuccess } = slice.actions;

export const toggleMkeDetails = (open) => async dispatch => {
    dispatch(mkeDetailsVisibilityChanged(open));
  };

export const fetchMke = (data) => async dispatch => {
    dispatch(startLoading());
    try {
        await api.post(`${apiPath}/puu`, data).then((response) => {
            dispatch(dataSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const resetMkeResults = () => async dispatch => {
    dispatch(dataSuccess([]));
    dispatch(storeSearchValues(null));
};

export const fetchMkeDetails = (mkeId) => async dispatch => {
    dispatch(startLoading());
    try {
        await api.get(`${apiPath}/detail/${mkeId}`).then((response) => {
            dispatch(detailSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const saveSearchValues = (values) => async dispatch => {
    dispatch(storeSearchValues(values));
};

export const fetchSelectedMke = (res) => async dispatch => {
    try {
        await api.get(`${apiPath}/valitood/${res.id}`).then((response) => {
            const selectedMke = response.data?.map(r => ({valitooId: r.id, nr: res.aktiNr, liik: 'MKE', geometry:JSON.parse(r.alaGeoJson) ?? null }));
            dispatch(addSelectedFeatures(selectedMke));
            dispatch(setActiveFeatures(selectedMke.map(t => (t.geometry))));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const fetchMkeFromValitooId = (valitooId) => async dispatch => {
    try {
        await api.get(`valitoo/selectedMke?valitooIdd=${valitooId}`).then((response) => {
            dispatch(selectedMkeSuccess(response.data?.map(r => r.mkeId)));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const resetSelectedMkeid = () => async dispatch => {
    dispatch(selectedMkeSuccess(null));
}

export const toggleExpanded = (result) => async dispatch => {
    dispatch(toggleExpandedResults(result));
};