import { Fab, Slide } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Colors } from "../style/Colors"
import { KeyboardArrowUp } from "@mui/icons-material";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(() => {
    const toggleVisibility = () => setIsVisible(window.pageYOffset > 500);

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  
  const Scroll = styled(Fab)(({
    position: 'fixed',
    bottom: 24,
    right: 24,
    zIndex: 2
  }));

  return <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
    <Scroll color="secondary" onClick={scrollToTop} aria-label="scroll to top">
      <KeyboardArrowUp color={Colors.blackCoral0} />
    </Scroll>
  </Slide>;
}