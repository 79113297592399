import {createSlice} from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'search',
    initialState: {
        searchDrawerOpen: false,
        currentTab: '',
    },
    reducers: {
        searchDrawerVisibilityChanged: (state, action) => {
            state.searchDrawerOpen = (action.payload !== undefined ? action.payload : !state.searchDrawerOpen);
        },
        currentSearchTabChanged: (state, action) => {
            state.currentTab = action.payload;
        }
    },
});

export default slice.reducer;

// Actions

const { searchDrawerVisibilityChanged, currentSearchTabChanged } = slice.actions;

export const toggleSearchDrawer = (open) => async dispatch => {
    dispatch(searchDrawerVisibilityChanged(open));
};

export const rememberCurrentTab = (tab) => async dispatch => {
    dispatch(currentSearchTabChanged(tab));
}