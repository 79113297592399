import * as React from "react";
import { useState } from "react";
import SearchTextField from "../form/SearchTextField";
import { SearchAutocompleteField } from "../form/SearchAutocompleteField";
import SearchSelect from "../form/SearchSelect";
import SearchCheckbox from "../form/SearchCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedTeatised, fetchTeatised, resetTeatisedResults, saveSearchValues, toggleExpanded } from "../../stores/teatis";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";
import { useNavigate } from "react-router-dom";
import StoreUtils from "../../utils/StoreUtils";

const teatisDefault = {
    eraldiseNr: '',
    katastriNr: '',
    kinnistuNimi: '',
    kinnistuNr: '',
    kvartaliNr: '',
    maakond: '',
    maakondKood: '',
    naitaAegunud: false,
    teatiseNr: '',
    tooKood: [],
    vald: '',
    valdKood: '',
};

export default function TeatisForm () {
    const { counties, municipalities, baasandmedLiik} = useSelector(state => state.classifier);
    const { result, isLoading, searchValues, expandedResults } = useSelector(state => state.teatis);
    const [county, setCounty] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { handleSubmit, reset, control, formState: { errors, isSubmitSuccessful }, setValue, getValues, register } = useForm({
        defaultValues: (searchValues ?? teatisDefault),
        resetOptions: {
            keepDirtyValues: true,
        },
    });

    const fetchResults = (data) => {
        const toParams = Object.fromEntries(Object.entries(data).filter(([_, v]) => v.length !== 0 && !!v));
        dispatch(saveSearchValues(form.getValues()));
        dispatch(fetchTeatised(toParams));
    };

    const resetResults = () => {
        dispatch(resetTeatisedResults());
    };
    
    const showSelectedTeatised = (result, object) => {
        const ids = [];
        if (result.alamYksused) {
            for (let i = 0; i < result.alamYksused.length; i++) {
                result.alamYksused[i].teatised?.forEach(t => ids.push(t.teatisId));
            }
        } else {
            const selectedIds = result.map(r => r.id);
            ids.push(...selectedIds);
        }
        dispatch(fetchSelectedTeatised(ids, object));
    };

    const getSubTreeInfo = (results) => {
        return { items: results.teatised.map(result => ({id: result.teatisId, nimi: result.nimi, geometry: null, color: StoreUtils.getTeatisStaatusColor(result) })), };
    };

    const openDetails = (id) => {
        navigate(`/teatis/${id}`);
    };
    
    const toggleResults = (result) => {
        dispatch(toggleExpanded(result));
    };
    
    const form = { handleSubmit:handleSubmit, reset:reset, control:control, formState: { errors:errors, isSubmitSuccessful:isSubmitSuccessful }, setValue:setValue, defaultValues:teatisDefault, getValues:getValues, register:register};

    const TeatisFields = ({setValue, control, errors, getValues}) => {          
        const filteredMunicipalities = municipalities.filter(muni => !!county? muni.maakondKood === county : getValues().maakondKood === muni.maakondKood); 
        const disabled = !filteredMunicipalities.length;

        return  <>
                    <SearchTextField control={control} name="katastriNr" label="Katastriüksus" pattern="cadastre" errors={errors}/>
                    <SearchTextField control={control} name='teatiseNr' label='Metsateatise nr'/>
                    <SearchAutocompleteField name="maakondKood" label="Maakond" options={counties} setValue={setValue} setCounty={setCounty} control={control}/>
                    <SearchAutocompleteField name="valdKood" label="Vald" options={filteredMunicipalities} disabled={disabled} setValue={setValue} control={control}/>
                    <SearchTextField control={control} name="kinnistuNimi" label="Kinnistu nimi"/>
                    <SearchTextField control={control} name="kinnistuNr" label="Kinnistu number" pattern='number' maxLength={10} errors={errors}/>
                    <SearchTextField control={control} name="kvartaliNr" label="Kvartali number"/>
                    <SearchTextField control={control} name="eraldiseNr" label="Eraldise number"/>
                    <SearchSelect control={control} name="tooKood" placeholder="Tööliik" multiple options={baasandmedLiik['TOOLIIK_TEATIS']} errors={errors} dataLabel='kood' />
                    <SearchCheckbox control={control} name='naitaAegunud' label="Näita ka aegunuid" />
                </>
    }

    return <SearchForm fields={TeatisFields({setValue:setValue, control:control, getValues:getValues, errors:errors })}
                form={form}    
                isLoading={isLoading}  
                result={result}     
                handleSubmit={handleSubmit}
                fetchResults={fetchResults}
                resetResults={resetResults}
                getSubTreeInfo={getSubTreeInfo}
                openDetails={openDetails}
                showLocationForMain={() => true}
                showGeometries={showSelectedTeatised}
                expandedResults={expandedResults}
                toggleExpanded={toggleResults}
                />;
};