import React from 'react';
import Colors from '../style/Colors';
import { CheckCircleOutline, Close, ErrorOutline, InfoOutlined } from '@mui/icons-material'
import { Snackbar } from '@mui/material';
import { styled } from "@mui/system";

const NotificationView = styled('div')(({
  maxWidth: '538px',
  minWidth: '338px',
  minHeight: '80px',
  borderRadius: '4px',
  display: 'inline',
  justifyContent: 'space-between',
  color: 'white',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  padding: '1rem'
}));

const NotificationHeader = styled('div')(({
  display: 'flex',
  paddingBottom: '0.5rem',
}));

const NotificationTitle = styled('div')(({
  fontSize: '1.3rem',
  width: '100%'
}));


export function Notification({ open, message, type, onClose, autoHide, ...rest }) {
    const styles = {
      backgroundColor: Notification.backgroundColors[type] || Colors.sapphireBlue4,
      borderColor: Notification.borderColors[type] || Colors.sapphireBlue,
      borderStyle: 'solid',
      borderRadius: '5px',
      color: Colors.notificationText,
    };
  
    return <> 
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          paddingTop: '60px'
        }}
        open={open}
        {...autoHide && { autoHideDuration: 6000}}
        onClose={onClose}
        {...rest}
      >
        
        <NotificationView className='notification' style={styles}>
          <NotificationHeader>
            <NotificationTitle>{NotificationIcon({ type })}</NotificationTitle>
            <div onClick={onClose}>
              <Close color={styles.color} />
            </div>
          </NotificationHeader>
          <div style={{whiteSpace: 'pre-wrap'}}>
              {message}
          </div>
        </NotificationView>
      </Snackbar>
    </>
  }
  
  Notification.backgroundColors = {
    info: Colors.darkTangerine1,
    success: Colors.mintCream,
    warning: Colors.darkTangerine1,
    error: Colors.lavenderBlush,
  };
  
  Notification.borderColors = {
    info: Colors.darkTangerine10,
    success: Colors.seaGreen,
    warning: Colors.darkTangerine10,
    error: Colors.jasper,
  };
    
  function NotificationIcon({ type }) {
    switch (type) {
      case 'info':
        return "Teade";
      case 'warning':
        return <InfoOutlined />;
      case 'success':
        return <CheckCircleOutline />;
      case 'error':
      default:
        return <ErrorOutline />;
    }
  }

  export default Notification;