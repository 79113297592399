import * as React from 'react';
import { TableCell } from '@mui/material';

const CellWithGridline = ({content, colspan}) => {

  return <TableCell colSpan={colspan} sx={{ verticalAlign: 'top',
                                            textAlign: 'center',
                                            padding: '8px 4px',
                                            fontSize: '1rem',
                                            '&:first-of-type': { 
                                              fontWeight: 'bold',      
                                              textAlign: 'left',
                                            } 
                                          }}>
            {content}
          </TableCell>;
};

export default CellWithGridline;