import * as React from 'react';
import { TableCell } from '@mui/material';
import Colors from '../style/Colors';

const TableHeadCellTwoLines = ({content, colspan, firstLine, alignTop}) => {

  return <TableCell colSpan={colspan} sx={{ color: Colors.blackCoral20, 
                                            fontWeight: 'bold',
                                            padding: '4px 4px',
                                            fontSize: '1rem',
                                            textAlign: 'center',
                                            ...(firstLine && { paddingBottom: 0, borderBottom: 0 }),
                                            ...(!firstLine && { paddingTop: 0 }),
                                            ...(alignTop && { verticalAlign: 'top' }),
                                            ...(!alignTop && { verticalAlign: 'bottom' }),
                                            '&:first-of-type': {   
                                              textAlign: 'left',
                                            } 
                                            }}>
            {content}
          </TableCell>;
};

export default TableHeadCellTwoLines;