import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { LocationOnOutlined, ArrowForwardIosSharp as ExpandIcon } from "@mui/icons-material";
import SubTreeItems from "./SubTreeItems";
import { useDispatch, useSelector } from "react-redux";
import { toggleSearchDrawer } from "../../stores/search";
import { useNavigate } from "react-router-dom";

const ResultTreeWrapper = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px 12px',
  backgroundColor: '#fff'
}));

const ResultSummary = styled(AccordionSummary)(({
  flexDirection:'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      boxShadow:'0 !important',
  },
}));

export default function ResultTree({ res, resIndex, defaultExpanded, getSubTreeInfo, openDetails, showLocationForMain, showGeometries, showCadastre, toggleExpanded, expandedResults }) {
    const mobile = useSelector(state => state.global.mobileView);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showMap = (res) => {
        showGeometries(res);
        if (mobile) {
            dispatch(toggleSearchDrawer(false));
            navigate(`/kaart`);
          }
    };

    return (
        <ResultTreeWrapper>
            <Accordion disableGutters
                       defaultExpanded={defaultExpanded || expandedResults.indexOf(res.nimi+resIndex) > -1}
                       onChange={() => toggleExpanded(res.nimi+resIndex)}
                       className="search-field"
                       sx={{background: '#F0F0F2', border: "0 !important"}}
            >
                <ResultSummary
                    sx={{background:'#F9F9F9'}}
                    expandIcon={res.alamYksused.length? <ExpandIcon sx={{fontSize: '0.9rem'}}/> : null}
                    items={res.alamYksused}
                >
                    <Box sx={{display: 'flex', flex: 1}}>
                        <Typography sx={{marginLeft:1}}>{res.nimi}</Typography>
                    </Box>
                    <Box onClick={(e) => e.stopPropagation()} sx={{display: 'flex'}}>
                        {showLocationForMain(res) && <LocationOnOutlined onClick={() => showMap(res)}/>}
                    </Box>
                </ResultSummary>

                {res.alamYksused.map((yksus, index) => (
                    <SubTreeItems key={index} 
                                    results={yksus} 
                                    defaultExpanded={res.alamYksused.length === 1} 
                                    getSubTreeInfo={getSubTreeInfo} 
                                    openDetails={openDetails}
                                    showGeometries={showGeometries} 
                                    showCadastre={showCadastre}
                                    expandedResults={expandedResults}
                                    toggleExpanded={toggleExpanded}
                                    />
                ))}
            </Accordion>
        </ResultTreeWrapper>
    )
};