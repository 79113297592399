import {createSlice} from "@reduxjs/toolkit";
import {api} from "../api";
import StoreUtils from "../utils/StoreUtils";

const apiPath = "mue";

const slice = createSlice({
    name: 'mue',
    initialState: {
        criteria: {},
        result: [],
        selectedResult: null,
        selectedGeometry: null,
        detailsOpen: false,
        isLoading: false,
        error: false,
        searchValues: null,
        expandedResults: []
    },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        dataSuccess: (state, action) => {
            state.result = action.payload;
            state.expandedResults = [];
            state.isLoading = false;
        },
        detailSuccess: (state, action) => {
            state.selectedResult = action.payload;
            state.isLoading = false;
            state.detailsOpen = true;
        },    
        geometrySelected: (state, action) => {
            state.selectedGeometry = action.payload;
        },    
        mueDetailsVisibilityChanged: (state, action) => {
            state.detailsOpen = (action.payload !== undefined ? action.payload : !state.detailsOpen);
        },
        storeSearchValues: (state, action) => {
            state.searchValues = action.payload;
        },
        toggleExpandedResults: (state, action) => {
            const result = action.payload;
            state.expandedResults.indexOf(result) > -1 ? state.expandedResults.splice(result, 1) : state.expandedResults.push(result);
        }
    },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, dataSuccess, detailSuccess, mueDetailsVisibilityChanged, geometrySelected, storeSearchValues, toggleExpandedResults } = slice.actions;

export const toggleMueDetails = (open) => async dispatch => {
    dispatch(mueDetailsVisibilityChanged(open));
};

export const resetMueResults = () => async dispatch => {
    dispatch(dataSuccess([]));
    dispatch(storeSearchValues(null));
};

export const fetchMue = (data) => async dispatch => {
    dispatch(startLoading());
    try {
        await api.post(`${apiPath}/puu`, data).then((response) => {
            dispatch(dataSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const fetchMueDetails = (valitooId) => async dispatch => {
    dispatch(startLoading());
    try {
        await api.get(`${apiPath}/detailValitoo/${valitooId}`).then((response) => {
            dispatch(detailSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const selectGeometry = (geometry) => async dispatch => {
    dispatch(geometrySelected(geometry));
};

export const saveSearchValues = (values) => async dispatch => {
    dispatch(storeSearchValues(values));
};

export const toggleExpanded = (result) => async dispatch => {
    dispatch(toggleExpandedResults(result));
};

