import { useEffect, forwardRef } from 'react';
import {Dialog, Drawer, Slide} from "@mui/material";
import { useDispatch, useSelector} from "react-redux";
import '../components/map/Search.css';
import '../components/map/Drawer.css';
import DrawerHeader from '../components/table/DrawerHeader';
import { toggleSearchDrawer } from '../stores/search';
import Colors from '../components/style/Colors';
import Shadows from '../components/style/Shadows';
import EraldisForm from '../components/search/EraldisForm';
import TeatisForm from '../components/search/TeatisForm';
import MkeForm from '../components/search/MkeForm';
import MueForm from '../components/search/MueForm';
import { MapSearch } from '../components/map/MapSearch';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const SearchFieldControl = () => {
    const { currentTab } = useSelector(state => state.search);
    const label = currentTab || 'EraldisForm';

    switch (label) {
        case 'EraldisForm':
            return <EraldisForm />;
        case 'TeatisForm':
            return <TeatisForm />;
        case 'MkeForm':
            return <MkeForm />;
        case 'MueForm':
            return <MueForm />;
        case 'MapSearch':
            return (<MapSearch/>);
        default:
            break;
    }
}

function MobileSearchDrawer({open, close}) {
     return (
        <Dialog PaperProps={{sx: {backgroundColor: Colors.extraLight}}} classes={{paper:'drawer-mobile'}} fullScreen open={open} TransitionComponent={Transition}>
            <DrawerHeader title="Otsing" close={true} handleClose={close} />
            <SearchFieldControl/>
        </Dialog>
    );
}

function DesktopSearchDrawer({ open, close }) {
    return (
        <Drawer PaperProps={{sx: {backgroundColor: Colors.extraLight,     
                                    display: 'flex',
                                    zIndex: 1200,
                                    width: '425px',
                                    marginTop: '102px',
                                    height: 'calc(100vh - 102px)',
                                    boxShadow: Shadows.shadow2,
                                    paddingBottom: '150px'}}}
                open={open} anchor={"left"} variant="persistent">

            <DrawerHeader title="Otsing" handleClose={close} />
            <SearchFieldControl/>
        </Drawer>
    );
}

export default function Search() {
    const { searchDrawerOpen } = useSelector(state => state.search);
    const desktop = !useSelector(state => state.global.mobileView);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleSearchDrawer(true));
    }, [dispatch]);

    const handleClose = () => {
        dispatch(toggleSearchDrawer(false));
     };

    return (
        <>
            {
                !!desktop ?
                <DesktopSearchDrawer
                    open={searchDrawerOpen}
                    close={handleClose}/>
                    :
                <MobileSearchDrawer
                    open={searchDrawerOpen}
                    close={handleClose}/>
            }
        </>);
}
