export const MapConstants = {
  initialZoom: 0,
  initialCenter: [552500, 6505000],
  extent: [40500, 5993000, 1064500, 7017000]
};

export const GeometryType = {
  POINT: 'Point',
  MULTI_POINT: 'MultiPoint',
  LINE_STRING: 'LineString',
  MULTI_LINE_STRING: 'MultiLineString',
  POLYGON: 'Polygon',
  MULTI_POLYGON: 'MultiPolygon',
  CIRCLE: 'Circle'
};