import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';
import StoreUtils from '../utils/StoreUtils';

// Slice
const slice = createSlice({
  name: 'notification',
  initialState: {
    message: "",
    open: false,
    showLoadingOveray: false,
    type: "info",
    autoHide: true,
  },
  reducers: {
    hidden: state => {
      state.open = false;
      state.message = "";
    },
    visible: (state, action) => {
      state.open = true;
      state.type = action.payload.type;
      state.autoHide = action.payload.parameters !== undefined && action.payload.parameters.autoHide !== undefined ? 
                                                                                action.payload.parameters.autoHide : true;
      if (action.payload.message) {
        state.message = action.payload.message;
      }
      if (action.payload.messages) {
        state.message = action.payload.messages.map(message => {
          if (message instanceof Object && message.message) {
            return message.message;
          } else {
            return message;
          }
        }).join('\n');
      }
    },
    loadingOverlaySuccess: (state, action) => {
      if (state.showLoadingOveray !== action.payload) {
        state.showLoadingOveray = action.payload;
      }
    }
  },
});

export default slice.reducer;

// Actions

const { hidden, visible, loadingOverlaySuccess, hasError } = slice.actions;

export const hideNotification = () => async dispatch => {
  dispatch(hidden());
};

export const showInfo = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "info", message, parameters }));
};

export const showInfos = (messages, parameters) => async dispatch => {
  dispatch(visible({ type: "info", messages, parameters}));
};

export const showSuccess = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "success", message, parameters }));
};

export const showWarning = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "warning", message, parameters }));
};

export const showWarnings = (messages) => async dispatch => {
  dispatch(visible({ type: "warning", messages }));
};

export const showError = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "error", message, parameters }));
};

export const toggleLoadingOverlay = (visible) => async dispatch => {
  dispatch(loadingOverlaySuccess(visible));
};

export const fetchActiveNotifications = () => async dispatch => {
  try {
      await api.get(`/teade/kehtivad`).then((response) => {
        if (response.data && response.data.length > 0) {
          let messages = [];
          response.data.forEach(element => {
            messages.push(element.tekst);
          });
          let parameters = {
            autoHide: false
          }
          dispatch(showInfos(messages, parameters));
        }
      });
  }
  catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
  }
};


