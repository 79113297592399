import { Button } from '@mui/material';
import * as React from 'react';
import Colors from '../style/Colors';


const DetailInfoButton = ({title, handleClick}) => {

  return  <Button variant="outlined" size="small" onClick={handleClick} sx={{border: '2px solid',
                                                                borderRadius:"2rem",
                                                                minWidth: 92,  
                                                                borderColor: Colors.blackCoral,  
                                                                color: Colors.blackCoral,
                                                                lineHeight: 1,
                                                                margin: "8px",
                                                                float: "right",
                                                                '&:hover': {      
                                                                  border: '2px solid',      
                                                                  borderColor: Colors.blackCoral,      
                                                                  backgroundColor: Colors.blackCoral0,      
                                                                  color: Colors.blackCoral,
                                                                },}}>
            {title}
          </Button>;
};

export default DetailInfoButton;