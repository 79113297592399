import { Box, Checkbox, Collapse, Dialog, Divider, Drawer, FormControlLabel, List, ListItem, ListItemText, Slide } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { toggleLayerGroup, toggleLayer, toggleGroupVisibility, toggleLayerDrawer } from "../../stores/map/map";
import clsx from 'clsx';
import GeneralUtils from "../../utils/GeneralUtils";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import './Drawer.css';
import DrawerHeader from "../table/DrawerHeader";
import Colors from "../style/Colors";
import Shadows from "../style/Shadows";

const CheckBoxWithoutPadding = styled(Checkbox)(({
  paddingTop: 0, 
  paddingBottom: 0,
}));

const LegendIcon = styled('img')(({
  height: 24
}));

const LayersList = styled(List)(({
  paddingTop: 0,
  paddingLeft: 32
}))

const MainLayer = styled('span')(({
  color: Colors.sapphireBlue,
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
}))

const SubLayer = styled('span')(({
  color: Colors.blackCoral20,
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}))

function CheckboxListItem({ item, handleClick }) {

  const legend = item.legendUrl !== undefined && item.legendUrl;

  let getLegendIcon = () => {
    return legend ? <LegendIcon src={legend} alt="legend" /> : null;
  }

  return <span>
    <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} onClick={handleClick} >
      <ListItemText sx={{ paddingTop: 0, paddingBottom: 0 }} secondary={
        <FormControlLabel
          control={<CheckBoxWithoutPadding color="primary" checked={item.visible} size="small" />}
          label={
            <SubLayer>              
              {item.title}        
            </SubLayer>
          }
        />
      } />
      {getLegendIcon()}
    </ListItem>
  </span>;
}

let getArrow = (group) => {
  return group.open ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />);
}

function GroupListItem({ group, handleOpenClick, handleVisibilityClick, isPartiallyChecked }) {

  return <ListItem onClick={() => handleOpenClick(group)}
    classes={{ root: clsx(isPartiallyChecked && 'partially-checked') }}>
    <ListItemText
      secondary={<MainLayer>
        <CheckBoxWithoutPadding color="primary" checked={group.visible} onClick={(e) => { e.stopPropagation(); handleVisibilityClick(group); }} size="small" />
        {group.title}
      </MainLayer>}/>
      {getArrow(group)}
  </ListItem>;
}
function GroupLayerList({ group, layers, handleGroupClick, handleGroupVisibilityClick,
  handleLayerClick }) {

  const groupLayers = layers.filter(l => l.mapGroup?.id === group.id);
  const subGroupsAndLayers = GeneralUtils.sortArrayByNumber([...groupLayers], 'order');

  let isPartiallyChecked;
  subGroupsAndLayers?.filter(el => el.visible).length > 0 ? isPartiallyChecked = true : isPartiallyChecked = false;

  const renderLayer = (layer, index) => <CheckboxListItem key={index} item={layer} handleClick={() => handleLayerClick(layer)} />;

  return <span>
    <GroupListItem group={group} handleOpenClick={handleGroupClick} handleVisibilityClick={handleGroupVisibilityClick} isPartiallyChecked={isPartiallyChecked} />
    <Collapse in={group.open} timeout="auto" unmountOnExit>
      <LayersList component="div" dense>
        {subGroupsAndLayers.map((subGroupOrLayer, index) => {
          return renderLayer(subGroupOrLayer, index)
        })}
      </LayersList>
    </Collapse>
    <Divider className='divider' />
  </span>
}

function LayerList() {
  const dispatch = useDispatch();
  const { layers, layerGroups } = useSelector(state => state.map);

  const handleGroupClick = (index) => {
    dispatch(toggleLayerGroup(index));
  };

  const handleGroupVisibilityClick = (group) => {
    dispatch(toggleGroupVisibility(group));
  };

  const handleLayerClick = (layer, group) => {
    dispatch(toggleLayer(layer, group));
  };

  return <Box mx={'12px'} px={'12px'} pb={'1rem'} sx={{ background: 'white'}}>
      <List  dense>
      {layerGroups.map((group, groupIndex) => (
        <GroupLayerList key={groupIndex}
          group={group} layers={layers}
          handleGroupClick={handleGroupClick}
          handleGroupVisibilityClick={handleGroupVisibilityClick}
          handleLayerClick={handleLayerClick}
        />
      ))}
    </List>
  </Box> ;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MobileLayerDrawer({ open, close }) {

return <Dialog PaperProps={{sx: {backgroundColor: Colors.extraLight}}} classes={{paper:'drawer-mobile'}} fullScreen open={open} TransitionComponent={Transition}>
    <DrawerHeader title="Kaardikihid" close={true} handleClose={close} />
    <LayerList />
  </Dialog>
}

function DesktopLayerDrawer({ open, close }) {
  return <Drawer anchor="left" open={open} variant="persistent" PaperProps={{sx: {backgroundColor: Colors.extraLight,
                                                                                  width: '425px;',    
                                                                                  marginTop: '102px',    
                                                                                  height: 'calc(100vh - 102px)',    
                                                                                  boxShadow: Shadows.shadow2,
                                                                                  paddingBottom: '150p'}}} >
    <DrawerHeader title="Kaardikihid" handleClose={close} />
    <LayerList />
  </Drawer>
}

export default function LayerDrawer() {
  const dispatch = useDispatch();
  const { layerDrawerOpen } = useSelector(state => state.map);
  const desktop = !useSelector(state => state.global.mobileView);

  const handleClose = () => {
    dispatch(toggleLayerDrawer(false));
 };

  return <>
    {desktop ?
      <DesktopLayerDrawer
        open={layerDrawerOpen}
        close={handleClose}
      /> :
      <MobileLayerDrawer
        open={layerDrawerOpen}
        close={handleClose}
      />}
  </>;
}