import {Autocomplete, TextField} from "@mui/material";
import {styled} from "@mui/system";
import {Controller} from "react-hook-form";

const SearchAutocomplete = styled(Autocomplete)(({
    paddingBottom: '0.5em',
    width: '95%',
}))

export const SearchAutocompleteField = ({ name, label, options, setValue, setCounty, disabled, control }) => {
    const handleChange = (value) => {
        if (name === 'maakondKood' || name === 'mkood') {
            setValue('maakond',value);
            setValue('vald', '');
            if (name === 'mkood')
                setValue('vkood', '');
            if (name === 'maakondKood')
                setValue('valdKood','');
        }
        if (name === 'valdKood' || name === 'vkood') {
            setValue('vald', value);
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            defaultValue=""
            render={({ field: {onChange, value}}) => (
                <SearchAutocomplete
                    value={options.find(opt=> opt.kood === value) || null}
                    disabled={disabled}
                    label={label}
                    options={options}
                    getOptionLabel={o => o.nimi || ""}
                    onChange={(e, value, reason) => {
                        if (reason === 'clear') {
                            onChange("")
                            handleChange("");
                            if (name === 'maakondKood') {
                                setCounty("");
                            }
                        }
                        else {
                            handleChange(value.nimi, reason);
                            onChange(value.kood);
                            if (setCounty) {
                                setCounty(value.kood);
                            }
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={label}
                        />
                    }
                />
            )}
            onChange={(_,value) => value}
        />
    )
}
