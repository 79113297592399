import * as React from "react";
import { useState } from "react";
import SearchTextField from "../form/SearchTextField";
import { SearchAutocompleteField } from "../form/SearchAutocompleteField";
import SearchCheckbox from "../form/SearchCheckbox";
import SearchSelect from "../form/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SearchForm from "./SearchForm";
import { fetchMke, fetchSelectedMke, resetMkeResults, saveSearchValues, toggleExpanded } from "../../stores/mke";
import { useNavigate } from "react-router-dom";

const mkeDefault = {
    aktiNr: '',
    katastriNr: '',
    kinnistuNimi: '',
    kinnistuNr: '',
    kvartaliNr: '',
    maakond: '',
    mkood: '',
    naitaAegunud: false,
    eraldiseNr: '',
    tooliik: [],
    vald: '',
    vkood: '',
};

export default function MkeForm () {
    const { counties, municipalities, baasandmedLiik} = useSelector(state => state.classifier);
    const { result, isLoading, searchValues, expandedResults } = useSelector(state => state.mke);
    const [county, setCounty] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { handleSubmit, reset, control, formState: { errors, isSubmitSuccessful }, setValue, getValues, register } = useForm({
        defaultValues: (searchValues ?? mkeDefault),
        resetOptions: {
            keepDirtyValues: true,
        },
    });

    const fetchResults = (data) => {
        dispatch(saveSearchValues(form.getValues()));
        dispatch(fetchMke(data));
    };

    const resetResults = () => {
        dispatch(resetMkeResults());
    };
        
    const showSelectedMke = (result) => {
        dispatch(fetchSelectedMke(result[0]));
    };
    
    const getSubTreeInfo = (results) => {
        return { items: results.mked.map(result => ({id: result.mkeId, nimi: result.nimi, geometry: null, aktiNr: result.aktiNr })), };
    };

    const openDetails = (id) => {
        navigate(`/mke/${id}`);
    };
    
    const toggleResults = (result) => {
        dispatch(toggleExpanded(result));
    };

    const form = { handleSubmit:handleSubmit, reset:reset, control:control, formState: { errors:errors, isSubmitSuccessful:isSubmitSuccessful }, setValue:setValue, defaultValues:mkeDefault, getValues:getValues, register:register};

    const MkeFields = ({setValue, control, errors, getValues}) => {          
        const filteredMunicipalities = municipalities.filter(muni => !!county? muni.maakondKood === county : getValues().mkood === muni.maakondKood); 
        const disabled = !filteredMunicipalities.length;

        return  <>
                    <SearchTextField control={control} name="katastriNr" label="Katastriüksus" pattern="cadastre" errors={errors}/>
                    <SearchTextField control={control} name='aktiNr' label='MKE nr'/>
                    <SearchAutocompleteField name="mkood" label="Maakond" options={counties} setValue={setValue} setCounty={setCounty} control={control}/>
                    <SearchAutocompleteField disabled={disabled} name="vkood" label="Vald" options={filteredMunicipalities} setValue={setValue} control={control} />
                    <SearchTextField control={control} name="kinnistuNimi" label="Kinnistu nimi"/>
                    <SearchTextField control={control} name="kinnistuNr" label="Kinnistu number" pattern='number' maxLength={10} errors={errors}/>
                    <SearchTextField control={control} name="kvartaliNr" label="Kvartali number"/>
                    <SearchTextField control={control} name="eraldiseNr" label="Eraldise number"/>
                    <SearchSelect control={control} name="tooliik" placeholder="Soovitusliku töö liik" multiple options={baasandmedLiik['TOOLIIK_TEATIS']} errors={errors} dataLabel='kood' />
                    <SearchCheckbox control={control} name='naitaAegunud' label="Näita ka aegunuid"/>
                </>
    };

    return  <SearchForm fields={MkeFields({setValue:setValue, control:control, getValues:getValues, errors:errors })}
                handleSubmit={handleSubmit}
                result={result}
                isLoading={isLoading}
                form={form}
                fetchResults={fetchResults}
                resetResults={resetResults}
                getSubTreeInfo={getSubTreeInfo}
                openDetails={openDetails}
                showLocationForMain={() => false}
                showCadastre={() => true}
                showGeometries={showSelectedMke}
                expandedResults={expandedResults}
                toggleExpanded={toggleResults}
                />;
};