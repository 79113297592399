import * as React from 'react';
import { Drawer, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../style/Colors';
import { ArrowForwardIos } from '@mui/icons-material';
import { toggleMinDrawer } from '../../stores/map/map';
import { useNavigate } from 'react-router-dom';
import { toggleMinimizeFeaturesDialog } from '../../stores/map/feature';

const MinDrawer = () => {
  const { layerDrawerOpen, minData, minDrawerOpen } = useSelector(state => state.map);
  const eraldisOpen = useSelector(state => state.eraldis.detailsOpen);
  const teatisOpen = useSelector(state => state.teatis.detailsOpen);
  const mkeOpen = useSelector(state => state.mke.detailsOpen);
  const mueOpen = useSelector(state => state.mue.detailsOpen);
  const { searchDrawerOpen } = useSelector(state => state.search);
  const mobile = useSelector(state => state.global.mobileView); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
    
  const anyDrawerOpen = () => {
    return !mobile && (layerDrawerOpen || searchDrawerOpen || eraldisOpen || teatisOpen || mkeOpen || mueOpen);
  };

  const handleClick = () => {
    if (minData?.features) {
      dispatch(toggleMinimizeFeaturesDialog(true));
    } else {
      navigate(-1);
    }    
    dispatch(toggleMinDrawer(false));
  };

  return <Drawer onClick={() => handleClick()} open={minDrawerOpen} anchor="bottom" variant="persistent" PaperProps={{sx: {...(anyDrawerOpen() && {marginLeft: '425px'}),
                                                                                              ...(!anyDrawerOpen() && {marginLeft: '4px'}), 
                                                                                              ...(!mobile && {maxWidth: '15rem'}),
                                                                                              overflowY: 'hidden',
                                                                                              '&::-webkit-scrollbar': {
                                                                                                display: 'none'
                                                                                              },
                                                                                              marginRight: '100px',
                                                                                              marginBottom: '48px',
                                                                                              borderRadius: '8px',
                                                                                              }}} >
    <div style={{ color: Colors.blackCoral12,
                  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 20,
                  lineHeight: 1,
                  paddingLeft: '8px',
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center'
                  }}>
      {minData?.title}
      <IconButton aria-label="reopen object">                          
        <ArrowForwardIos />
      </IconButton>
    </div>
    
    </Drawer>;
};

export default MinDrawer;