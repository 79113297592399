import * as React from 'react';
import { styled } from '@mui/system';
import { TableRow } from '@mui/material';
import Colors from '../style/Colors';

const DetailOverview = styled(TableRow)(({
  background: Colors.blackCoral0,
}));


const TableRowOverview = ({content}) => {

  return <DetailOverview>
            {content}
          </DetailOverview>;
};

export default TableRowOverview;