import { useEffect, forwardRef } from 'react';
import {Dialog, Drawer, Slide} from "@mui/material";
import '../components/map/Search.css';
import '../components/map/Drawer.css';
import DrawerHeader from '../components/table/DrawerHeader';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../components/form/LoadingIndicator';
import Colors from '../components/style/Colors';
import { styled } from '@mui/system';
import { toggleSearchDrawer } from '../stores/search';

const DetailsContent = styled('div')(({
    margin: '0px 12px',
    backgroundColor: '#fff'
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});


function MobileDetails({open, isLoading, title, content, geometry, color, handleClose}) {

     return (
        <Dialog PaperProps={{sx: {backgroundColor: Colors.extraLight}}} classes={{paper:'drawer-mobile'}} fullScreen open={open} TransitionComponent={Transition}>
            {isLoading && <LoadingIndicator/>}
            {(!isLoading && content !== null) && (<>
                <DrawerHeader title={title} close={true} handleClose={handleClose} geometry={geometry} leftBorder={color} />
                <DetailsContent>
                   {content} 
                </DetailsContent>                
            </>)}
        </Dialog>
    );
}

function DesktopDetails({ open, isLoading, title, content, geometry, color, handleClose }) {

    return (
        <Drawer PaperProps={{sx: { 
            height: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 102px)'},
            marginTop: { xs: '56px', md: '102px'},
            width: {md: '425px'},
            backgroundColor: Colors.extraLight
        }}} sx={{ inset: 'unset', background: Colors.blackCoral0}} anchor="left" open={open} hideBackdrop={true}>
            {isLoading && <LoadingIndicator/>}
            {(!isLoading && content !== null) && (<>
                <DrawerHeader title={title} handleClose={handleClose} geometry={geometry} leftBorder={color} />
                <DetailsContent>
                    {content}
                </DetailsContent>                
            </>)}
        </Drawer>
    );
}

export default function Details({open, loading, title, content, geometry, color, url, handleClose}) {
    const desktop = !useSelector(state => state.global.mobileView);
    const dispatch = useDispatch();
    const { searchDrawerOpen } = useSelector(state => state.search);
    
    useEffect(() => {
        if (searchDrawerOpen) {
            dispatch(toggleSearchDrawer(false));
        }
    }, [searchDrawerOpen, dispatch]);


    return (
        <>
            {
                !!desktop ?
                <DesktopDetails
                    open={open}
                    title={title}
                    content={content}
                    isLoading={loading}
                    geometry={geometry}
                    color={color}
                    handleClose={handleClose} />
                    :
                <MobileDetails
                    open={open}
                    title={title}
                    content={content}
                    isLoading={loading}
                    geometry={geometry}
                    color={color}
                    handleClose={handleClose} />
            }
        </>);
}
