import React from 'react';
import { styled } from "@mui/system";
import DefaultMap from '../components/map/DefaultMap';
import MeasureControl from '../components/map/controls/MeasureControl';

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: "calc(100vh - 102px)"
  },
  [theme.breakpoints.down('md')]: {
    height: "calc(100vh - 56px)"
  },
}));

export default function MainMap() {
  const controls = [<MeasureControl />];

  return (
    <Container>
      <DefaultMap extraControls={controls} />
    </Container>
  );
}