import {createSlice} from '@reduxjs/toolkit';
import {setActiveFeatures, toggleLayerDrawer} from './map';
import camelcaseKeys from 'camelcase-keys';
import {showError, showWarning} from '../notification';
import MapUtils from '../../utils/MapUtils';
import {api} from "../../api";

// Slice
const slice = createSlice({
  name: 'feature',
  initialState: {
    featuresDialogOpen: false,
    eraldised: [],
    teatised: [],
    valitood: [],
    infoObjectTypes: [],
    selectedInfoObject: null,
    objects: [],
    highlightFeatures: [],
    isLoading: false,
    error: false,
    loadingFeatures: [],
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
      if (action.payload) {
        state.loadingFeatures.push(action.payload);
      }
    },
    hasError: (state, action) => {
      if (action.payload.layerTitle) {
        state.loadingFeatures = state.loadingFeatures.filter(l => l !== action.payload.layerTitle);
        state.error = action.payload.error;
      }
      else {
        state.error = action.payload;
      }
      state.isLoading = false;
    },
    featuresDialogVisibilityChanged: (state, action) => {
      state.featuresDialogOpen = (action.payload !== undefined ? action.payload : !state.featuresDialogOpen);
      state.infoObjectTypes = [];
      state.objects = [];
    },
    featuresDialogVisibilityMinimized: (state, action) => {
      state.featuresDialogOpen = (action.payload !== undefined ? action.payload : !state.featuresDialogOpen);
    },
    clearFeaturesSuccess: (state, action) => {
      state.eraldised = [];
      state.teatised = [];
      state.valitood = [];
      state.infoObjectTypes = [];
      state.objects = [];
      state.loadingFeatures = [];
      state.selectedInfoObject = null;
      state.highlightFeatures = [];
    },
    featuresSuccess: (state, action) => {
      const features = action.payload;
      if (features && features.length) {
        const filteredObjects = features.map(f => {
          let objectFromProps = camelcaseKeys(f.properties);
          objectFromProps.geometry = f.geometry;
          return objectFromProps;
        });
        state.objects = [...state.objects, ...filteredObjects];
        state.eraldised = filteredObjects.filter(f => !!f.eraldisId);
        state.teatised = filteredObjects.filter(f => !!f.teatisId);
        state.valitood = filteredObjects.filter(f => !!f.valitooId);
      }
      featuresLoaded(state, 'local');
    },
    addFeatures: (state, action) => {
      const features = action.payload;      
      if (features && features.length) {
        state.objects = [...state.objects, ...features];
        state.eraldised = features.filter(f => !!f.eraldisId);
        state.teatised = features.filter(f => !!f.teatisId);
        state.valitood = features.filter(f => !!f.valitooId);
        state.featuresDialogOpen = true;
      }
    },
    externalFeaturesSuccess: (state, action) => {
      let features = action.payload.features;
      if (features.length) {
        features.forEach(f => {
          f.mapLayerTitle = action.payload.layerTitle;
          f.mapLayerLayerName = action.payload.layerLayerName;
        });
        state.objects = [...state.objects, ...features];

        const objectTypes = [...state.infoObjectTypes];
        objectTypes.push({
          type: 'json',
          code: action.payload.layerTitle,
          title: action.payload.layerTitle,
          objects: features
        });
        state.infoObjectTypes = objectTypes.filter((v, i, a) => a.findIndex(o => o.code === v.code) === i);
      }
      featuresLoaded(state, action.payload.layerTitle);
    },
    highlightFeaturesSuccess: (state, action) => {
      state.highlightFeatures = action.payload;
    },
    setSelectedInfoObjectSuccess: (state, action) => {
      state.selectedInfoObject = action.payload;
    },
  },
});

export default slice.reducer;

const featuresLoaded = (state, layer) => {
  state.loadingFeatures = state.loadingFeatures.filter(l => l !== layer);
  if (state.loadingFeatures.length === 0) {
    if (!state.objects.length) {
      state.featuresDialogOpen = false;
      showWarning("Kirjeid ei leitud")
    } else {
      if (state.infoObjectTypes.length === 1 && state.objects.length === 1) {
        state.selectedInfoObject = state.infoObjectTypes[0];
      }
      state.featuresDialogOpen = true;
    }
  }
}

// Actions

const { startLoading, hasError, featuresDialogVisibilityChanged, featuresDialogVisibilityMinimized, featuresSuccess, externalFeaturesSuccess,
  clearFeaturesSuccess, highlightFeaturesSuccess, setSelectedInfoObjectSuccess, addFeatures } = slice.actions;

export const clearFeatures = () => async dispatch => {
  dispatch(clearFeaturesSuccess());
  dispatch(setActiveFeatures([], false, true));
};

export const setSelectedInfoObject = (object) => async dispatch => {
  dispatch(setSelectedInfoObjectSuccess(object));
};

export const toggleFeaturesDialog = (open) => async dispatch => {
  dispatch(featuresDialogVisibilityChanged(open));
  if (open) {
    dispatch(toggleLayerDrawer(false));
  }
};

export const toggleMinimizeFeaturesDialog = (open) => async dispatch => {
  dispatch(featuresDialogVisibilityMinimized(open));
};

export const fetchClickedFeatures = (url, append) => async dispatch => {
  dispatch(startLoading('local'));
  try {
    await api.get('proxy', {params: { url: url }}).then((response) => {
      if (typeof response.data === 'string' && response.data.indexOf('ServiceExceptionReport') !== -1) {
        dispatch(showError("Viga kaardiobjektide laadimisel."));
      }
      dispatch(featuresSuccess(response.data?.features))
      dispatch(setActiveFeatures(response.data?.features, !!append, true));
    });
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: 'local' }));
    dispatch(showError("Viga kaardiobjektide laadimisel."));
  }
};

export const fetchClickedFeaturesExternal = (layerTitle, layerLayerName, url) => async dispatch => {
  dispatch(startLoading(layerTitle));
  try {
    await api.get('proxy', {params: { url: url }}).then((response) => {
      if (response.status === 200 && response.data) {
        let features = response.data?.features?.map(feature => {
          let parsed = feature.properties;
          parsed.geometry = feature.geometry;
          parsed.id = feature.id;
          return parsed;
        });

        dispatch(externalFeaturesSuccess({ features, layerTitle, layerLayerName }));
      }
    });
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: layerTitle }));
    dispatch(showError(`Viga kaardiobjektide laadimisel ${layerTitle}`));
  }
};

export const setHighlightGeometries = (geometries) => async dispatch => {
  dispatch(highlightFeaturesSuccess(MapUtils.geometriesToFeatures(geometries)));
};

export const addSelectedFeatures = (features) => async dispatch => {
  dispatch(addFeatures(features));
};