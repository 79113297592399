import React, { useEffect } from 'react';
import { Box, Collapse, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DateUtils from '../../utils/DateUtils';
import CellWithoutGridline from '../table/CellWithoutGridline';
import Details from '../../pages/Details';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Colors from '../style/Colors';
import { fetchEraldisDetails, toggleEraldisDetails } from '../../stores/eraldis';
import StoreUtils from '../../utils/StoreUtils';
import TableWithBorders from '../table/TableWithBorders';
import TableHeadCell from '../table/TableHeadCell';
import TableHeadCellTwoLines from '../table/TableHeadCellTwoLines';
import CellWithGridline from '../table/CellWithGridline';
import GeneralUtils from '../../utils/GeneralUtils';
import TableRowOverview from '../table/TableRowOverview';
import TableCellOverview from '../table/TableCellOverview';
import OpenAccordionIcon from '../table/OpenAccordionIcon';


function EraldisListItem(item) {
    const [open, setOpen] = React.useState(item.open);

    let toggleOpen = () => {
        setOpen(!open);
    }

    let getArrow = () => {
        return open ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />);
    }

    return <>
        <ListItem sx={{color: Colors.sapphireBlue}} onClick={() => toggleOpen()}>
            <ListItemText primaryTypographyProps={{ style: {fontSize: '1rem'} }} >{item.name}</ListItemText>
            {getArrow()}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            {item.content}
        </Collapse>
    </>
}

function EraldisGeneralInfo() {
    const { selectedResult } = useSelector(state => state.eraldis);

    return  <>       
            <Table sx={{marginBottom: '1.5rem'}}>
                <TableBody>
                  <TableRow>
                    <CellWithoutGridline content="Maakond" />
                    <CellWithoutGridline content={selectedResult.maakond} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Vald" />
                      <CellWithoutGridline content={selectedResult.vald} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Üksus" />
                      <CellWithoutGridline content={selectedResult.yksus.nimi} />
                  </TableRow>
                  {selectedResult.yksus.kinnistuNr &&
                    <TableRow>
                        <CellWithoutGridline content="Kinnistu nr" />
                        <CellWithoutGridline content={selectedResult.yksus.kinnistuNr} />
                    </TableRow>
                  }
                  <TableRow>
                      <CellWithoutGridline content="Katastritunnus" />
                      <CellWithoutGridline content={selectedResult.katastriNr} />
                  </TableRow>
                  {selectedResult.kvartaliNr &&
                    <TableRow>
                        <CellWithoutGridline content="Kvartal" />
                        <CellWithoutGridline content={selectedResult.kvartaliNr} />
                    </TableRow>
                  }
                  <TableRow>
                      <CellWithoutGridline content="Eraldis" />
                      <CellWithoutGridline content={selectedResult.eraldiseNr} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline sx={{wordWrap: 'break-word'}} content="Metsakorraldus&shy;ettevõte" />
                      <CellWithoutGridline content={selectedResult.korraldaja} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Inventeerimise kp" />
                      <CellWithoutGridline content={DateUtils.formatTimestampToDate(selectedResult.inventKp)} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Registrikande kp" />
                      <CellWithoutGridline content={DateUtils.formatTimestampToDate(selectedResult.regKp)} />
                  </TableRow>
                </TableBody>
            </Table>
            </>;
}

function EraldisGeneralValues() {
    const { selectedResult } = useSelector(state => state.eraldis);
    const { baasandmed } = useSelector(state => state.classifier);

    return  <>       
            <Table sx={{marginBottom: '1.5rem'}}>
                <TableBody>
                  <TableRow>
                    <CellWithoutGridline content="Pindala" />
                    <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(selectedResult.pindala, 'ha')} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Kõrgusindeks" />
                      <CellWithoutGridline content={selectedResult.korgus} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Boniteediklass" />
                      <CellWithoutGridline content={baasandmed['BONITEET'][selectedResult.boniteediKood]} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Kasvukohatüüp" />
                      <CellWithoutGridline content={baasandmed['KASVUKOHT'][selectedResult.kasvukohaKood]} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Kuivendus" />
                      <CellWithoutGridline content={selectedResult.kuivendatud ? 'jah' : 'ei'} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Peapuuliik" />
                      <CellWithoutGridline content={baasandmed['PUULIIK'][selectedResult.peapuuliik]} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Arenguklass" />
                      <CellWithoutGridline content={baasandmed['ARENGUKLASS'][selectedResult.arenguklKood]}  />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Keskmine vanus" />
                      <CellWithoutGridline content={selectedResult.keskmVanus} />
                  </TableRow>
                  { selectedResult.taisVaade && <>
                  <TableRow>
                      <CellWithoutGridline content="Raievanus" />
                      <CellWithoutGridline content={selectedResult.keskmRaievanus} />
                  </TableRow>
                  { selectedResult.keskmDiameeter &&
                    <TableRow>
                        <CellWithoutGridline content="Keskmine diameeter" />
                        <CellWithoutGridline content={selectedResult.keskmDiameeter} />
                    </TableRow>
                  }  
                  </> }
                  <TableRow>
                      <CellWithoutGridline content="Tuleohuklass" />
                      <CellWithoutGridline content={baasandmed['TULEOHUTUS'][selectedResult.tuleohuKood]} />
                  </TableRow>
                  { !selectedResult.taisVaade && <>
                  <TableRow>
                      <CellWithoutGridline content="Järelkasvu puude arv" />
                      <CellWithoutGridline content={(selectedResult.puudeArvJ ?? 0) + ' tk/ha'} />
                  </TableRow> 
                  <TableRow>
                      <CellWithoutGridline content="Lamapuidu maht" />
                      <CellWithoutGridline content={(selectedResult.tagavaraLHa ?? 0) + ' tm/ha'} />
                  </TableRow> 
                  <TableRow>
                      <CellWithoutGridline content="Põõsarinde liitus (%)" />
                      <CellWithoutGridline content={selectedResult.liitusA ?? 0} />
                  </TableRow> 
                  </> }
                </TableBody>
            </Table>
            </>;
}

function EraldisVolumes() {
    const { selectedResult } = useSelector(state => state.eraldis);

    return <Box mx={'16px'} mb={'1.5rem'}>
            <TableWithBorders sx={{margin: "0px 8px"}}content={<>
                <TableHead>
                    <TableRow>
                        <TableHeadCellTwoLines content="Rinne" firstLine={true} />
                        <TableHeadCellTwoLines content="Maht" colspan="2" firstLine={true} />
                        <TableHeadCellTwoLines content="Täius" firstLine={true}/>
                        <TableHeadCellTwoLines content="Rinnas&shy;pindala" firstLine={true}/>
                    </TableRow>
                    <TableRow>
                        <TableHeadCellTwoLines />
                        <TableHeadCellTwoLines content="tm" />
                        <TableHeadCellTwoLines content="tm/ha" />
                        <TableHeadCellTwoLines content="%" />
                        <TableHeadCellTwoLines content={<>m{<sup>2</sup>}/ha</>} />
                    </TableRow>
                </TableHead>                               
                <TableBody>
                    <TableRow>
                        <CellWithGridline content="Esimene" />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavara1Tm) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavara1Ha) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.taius1) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.rpindala1) ?? 0} />
                    </TableRow> 
                    <TableRow>
                        <CellWithGridline content="Teine" />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavara2Tm) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavara2Ha) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.taius2) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.rpindala2) ?? 0} />
                    </TableRow> 
                    <TableRow>
                        <CellWithGridline content="Üksikpuud" />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavaraYTm) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavaraYHa) ?? 0} />
                        <CellWithGridline colspan="2" />
                    </TableRow> 
                    <TableRow>
                        <CellWithGridline content="Kokku" />
                        <CellWithGridline content={GeneralUtils.sumArrayofValues([selectedResult.tagavara1Tm,
                                                                                    selectedResult.tagavara2Tm,
                                                                                    selectedResult.tagavaraYTm]) ?? 0} />
                        <CellWithGridline content={GeneralUtils.sumArrayofValues([selectedResult.tagavara1Ha,
                                                                                    selectedResult.tagavara2Ha,
                                                                                    selectedResult.tagavaraYHa]) ?? 0} />
                        <CellWithGridline colspan="2" />
                    </TableRow>
                    <TableRow>
                        <CellWithGridline content="Surnud mets" />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavaraSTm) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavaraSHa) ?? 0} />
                        <CellWithGridline colspan="2" />
                    </TableRow>  
                    <TableRow>
                        <CellWithGridline content="Lamapuit" />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavaraLTm) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.tagavaraLHa) ?? 0} />
                        <CellWithGridline colspan="2" />
                    </TableRow>  
                    <TableRow>
                        <CellWithGridline content="Juurdekasv" />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.juurdekasvTm) ?? 0} />
                        <CellWithGridline content={GeneralUtils.formatNumberValue(selectedResult.juurdekasv) ?? 0} />
                        <CellWithGridline colspan="2" />
                    </TableRow>  
                    <TableRow>
                        <CellWithGridline content="Põõsarinde liitus (%)" colspan="3" />
                        <CellWithGridline content={selectedResult.liitusA ?? 0} />
                        <CellWithGridline />
                    </TableRow>  
                </TableBody>
                </>
                } />
        </Box>
}

function EraldisTrees() {
    const { selectedResult } = useSelector(state => state.eraldis);

    return <Box mx={'16px'} mb={'1.5rem'}>
            <TableWithBorders content={<>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableHeadCell content="Rinne" />
                                    <TableHeadCell content="%" />
                                    <TableHeadCell content="Puuliik" />
                                    <TableHeadCell content="Hetke&shy;vanus" />
                                </TableRow>
                            </TableHead>                               
                            <TableBody>
                                {selectedResult.elemendid.map((element) => (
                                    <RowTrees key={element.id} row={element} />
                                ))}
                            </TableBody>
                            </>
                            } />
        </Box>
}

function RowTrees(element) {
    const { baasandmed } = useSelector(state => state.classifier);
    const { selectedResult } = useSelector(state => state.eraldis);
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
        <TableRowOverview sx={{ '& > *': { borderBottom: 'unset' } }} content={<>
            <TableCellOverview content={<OpenAccordionIcon handleClick={() => setOpen(!open)} open={open} />} />
            <TableCellOverview component="th" scope="row" content={baasandmed['RINNE'][element.row.rindeKood]} />
            <TableCellOverview component="th" scope="row" content={element.row.osakaal} />
            <TableCellOverview component="th" scope="row" content={baasandmed['PUULIIK'][element.row.puuliigiKood]} />
            <TableCellOverview component="th" scope="row" content={element.row.jooksevVanus} />
        </>} />
        <TableRow>
            <TableCell style={{ padding: '0px 2px' }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: '4px 0px' }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <CellWithoutGridline content="Aasta" />
                            <CellWithoutGridline content={element.row.aasta} />
                        </TableRow>
                        <TableRow>
                            <CellWithoutGridline content="Vanus" />
                            <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(element.row.vanus, 'a')} />
                        </TableRow>
                        <TableRow>
                            <CellWithoutGridline content="Kõrgus" />
                            <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(element.row.korgus, 'm')} />
                        </TableRow>
                        { selectedResult.taisVaade && <>
                            <TableRow>
                                <CellWithoutGridline content="Läbimõõt" />
                                <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(element.row.diameeter, 'cm')} />
                            </TableRow>
                            <TableRow>
                                <CellWithoutGridline content={<>Rinnaspindala</>} />
                                <CellWithoutGridline content={<>{GeneralUtils.getFieldWithUnit(element.row.gSumma, <>m{<sup>2</sup>}/ha</>)}</>} />
                            </TableRow>
                        </> }
                        <TableRow>
                            <CellWithoutGridline content="Tekkeviis" />
                            <CellWithoutGridline content={baasandmed['TEKKEVIIS'][element.row.paritoluKood]} />
                        </TableRow>
                        { selectedResult.taisVaade && <>
                            <TableRow>
                                <CellWithoutGridline content="Maht, tm" />
                                <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(GeneralUtils.formatNumberValue(element.row.mahtTm), 'tm')} />
                            </TableRow>
                            <TableRow>
                                <CellWithoutGridline content="Maht, tm/ha" />
                                <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(GeneralUtils.formatNumberValue(element.row.tagavara), 'tm/ha')} />
                            </TableRow>
                            <TableRow>
                                <CellWithoutGridline content="Puude arv" />
                                <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(element.row.arv, 'tk/ha')} />
                            </TableRow>
                        </> }
                    </TableBody>
                </Table>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
    );
}

function EraldisInjuries() {
const { selectedResult } = useSelector(state => state.eraldis);

return <Box mx={'16px'} mb={'1.5rem'}>
            <TableWithBorders content={<>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableHeadCell content="Rinne" />
                                    <TableHeadCell content="Puuliik" />
                                    <TableHeadCell content="Kahjustuse %" />
                                </TableRow>
                            </TableHead>                               
                            <TableBody>
                                {selectedResult.kahjustused.map((kahjustus) => (
                                    <RowInjuries key={kahjustus.id} row={kahjustus} />      
                                ))}
                            </TableBody>
                            </>
                            } />
        </Box>
}

function RowInjuries(kahjustus) {
    const { baasandmed } = useSelector(state => state.classifier);
    const [open, setOpen] = React.useState(false);

    return <React.Fragment key={kahjustus.id}>
    <TableRowOverview sx={{ '& > *': { borderBottom: 'unset' } }} content={<>
        <TableCellOverview content={<OpenAccordionIcon handleClick={() => setOpen(!open)} open={open} />} />
        <TableCellOverview component="th" scope="row" content={baasandmed['RINNE'][kahjustus.row.rindeKood]} />
        <TableCellOverview component="th" scope="row" content={baasandmed['PUULIIK'][kahjustus.row.puuliigiKood]} />
        <TableCellOverview component="th" scope="row" content={kahjustus.row.osakaal} />
    </>} />
    <TableRow>
        <TableCell style={{ padding: '0px 2px' }} colSpan={5}>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '4px 0px' }}>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <CellWithoutGridline content="Vanus" />
                        <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(kahjustus.row.vanus, 'a')} />
                    </TableRow>
                    <TableRow>
                        <CellWithoutGridline content="Kahjustaja" />
                        <CellWithoutGridline content={baasandmed['KAHJUSTUS'][kahjustus.row.pohjuseKood]} />
                    </TableRow>
                </TableBody>
            </Table>
            </Box>
        </Collapse>
        </TableCell>
    </TableRow>
    </React.Fragment>     
}

function EraldisWorks() {
    const { selectedResult } = useSelector(state => state.eraldis);

    return <Box mx={'16px'} mb={'1.5rem'}>
            <TableWithBorders content={<>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableHeadCell content="Tööliik" />
                                    <TableHeadCell content="Järjekord" />
                                    <TableHeadCell content="Puuliik" />
                                </TableRow>
                            </TableHead>                               
                            <TableBody>
                                {selectedResult.tood.map((too) => (
                                    <RowWorks key={too.id} row={too} />
                                ))}
                            </TableBody>
                            </>
                            } />
        </Box>
}

function RowWorks(too) {
    const { baasandmed } = useSelector(state => state.classifier);
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
        <TableRowOverview sx={{ '& > *': { borderBottom: 'unset' } }} content={<>
            <TableCellOverview content={<OpenAccordionIcon handleClick={() => setOpen(!open)} open={open} />} />
            <TableCellOverview component="th" scope="row" content={baasandmed['TOOLIIK'][too.row.tooKood]} />
            <TableCellOverview component="th" scope="row" content={baasandmed['JARJEKORD'][too.row.jarjekord]} />
            <TableCellOverview component="th" scope="row" content={baasandmed['PUULIIK'][too.row.puuliigiKood ? 
                                                                                            too.row.puuliigiKood : too.row.raiePuuliik]} />
        </>} />
        <TableRow>
            <TableCell style={{ padding: '0px 2px' }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: '4px 0px' }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <CellWithoutGridline content="Rinne" />
                            <CellWithoutGridline content={baasandmed['RINNE'][too.row.raieRindekood]} />
                        </TableRow>
                        <TableRow>
                            <CellWithoutGridline content="Töö aasta" />
                            <CellWithoutGridline content={too.row.aasta} />
                        </TableRow>
                        <TableRow>
                            <CellWithoutGridline content="Vanus" />
                            <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(too.row.raieVanus, 'a')} />
                        </TableRow>
                        <TableRow>
                            <CellWithoutGridline content="Raieprotsent" />
                            <CellWithoutGridline content={too.row.raieProtsent} />
                        </TableRow>
                        <TableRow>
                            <CellWithoutGridline content="Kogus" />
                            <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(too.row.kogusTm, 'tm')} />
                        </TableRow>
                    </TableBody>
                </Table>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
    );
}

function EraldisOtherInfo() {
    const { selectedResult } = useSelector(state => state.eraldis);
    const { baasandmed } = useSelector(state => state.classifier);

    return  <>       
            <Table>
                <TableBody>
                    {selectedResult.isearasused.map((isearasus, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell sx={{borderBottom: 'none', fontSize: '1rem'}}>{baasandmed['ISEARASUS'][isearasus.kood]}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}                  
                </TableBody>
            </Table>
            </>;
}

function EraldisDetailsAccordion() {
    const { selectedResult } = useSelector(state => state.eraldis);
    
    return  <> 
                <List dense>
                    <EraldisListItem name='Registrikanne' content={EraldisGeneralInfo()} open={true} />
                    <EraldisListItem name='Üldised näitajad' content={EraldisGeneralValues()} open={false}/>
                    {selectedResult.taisVaade && <EraldisListItem name='Mahud' content={EraldisVolumes()} open={false}/>}
                    {selectedResult.elemendid.length > 0 && <EraldisListItem name='Puuliigid' content={EraldisTrees()} open={false}/>}
                    {selectedResult.kahjustused.length > 0 && <EraldisListItem name='Kahjustused' content={EraldisInjuries()} open={false}/>}
                    {selectedResult.taisVaade && selectedResult.tood.length > 0 && <EraldisListItem name='Tööd' content={EraldisWorks()} open={false}/>}
                    {selectedResult.isearasused.length > 0 && <EraldisListItem name='Iseärasused' content={EraldisOtherInfo()} open={false}/>}
                </List>     
            </>;
}

export default function EraldisDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { selectedResult, detailsOpen, isLoading } = useSelector(state => state.eraldis);

    const eraldisId = selectedResult?.id;
    const drawerTitle = "Eraldis " + selectedResult?.eraldiseNr;

    const shouldFetch = !eraldisId || eraldisId !== Number(id);

    useEffect(() => {
        if (shouldFetch) { 
          dispatch(fetchEraldisDetails(id));
        } else {
          dispatch(toggleEraldisDetails(true));
        }
    }, [shouldFetch, id, dispatch]);

    const handleClose = () => {
        dispatch(toggleEraldisDetails(false));
    }

    const getGeometry = () => {
        return selectedResult ? [JSON.parse(selectedResult.alaGeoJson)] : undefined;
    }

    return  <Details 
                open={detailsOpen}
                handleClose={handleClose} 
                title={drawerTitle} 
                loading={isLoading} 
                content={<EraldisDetailsAccordion selectedResult={selectedResult} />}
                geometry={getGeometry()}
                color={StoreUtils.getEraldisStaatusColor(selectedResult)}
                />;
}
