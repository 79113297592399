import * as React from 'react';
import { styled } from '@mui/system';
import { TableCell } from '@mui/material';
import Colors from '../style/Colors';

const DetailHead = styled(TableCell)(({
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  verticalAlign: 'top',
  color: Colors.blackCoral20
}));


const TableHeadCell = ({content}) => {

  return <DetailHead>
            {content}
          </DetailHead>;
};

export default TableHeadCell;