import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import "./Map.css";
import MapContext from "./MapContext";
import * as ol from "ol";
import { useDispatch, useSelector } from "react-redux";
import { setCenterZoom } from "../../stores/map/map";
import { MapConstants } from "../../constants/mapConstants";
import MapUtils from "../../utils/MapUtils";
import { defaults as defaultInteractions } from "ol/interaction.js";
import { styled } from "@mui/system";

const MapDiv = styled('div')(({
  width: '100%',
}));

const Map = ({ children, zoom, center, extent, disablePan }) => {
  const mapRef = useRef();
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.global.mobileView);
  const { layerDrawerOpen } = useSelector(state => state.map);
  const eraldisOpen = useSelector(state => state.eraldis.detailsOpen);
  const teatisOpen = useSelector(state => state.teatis.detailsOpen);
  const mkeOpen = useSelector(state => state.mke.detailsOpen);
  const mueOpen = useSelector(state => state.mue.detailsOpen);
  const { searchDrawerOpen } = useSelector(state => state.search);

  const [map, setMap] = useState(null);

  const [render, reRender] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      reRender(!render);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [render]);


  // on component mount
  useEffect(() => {
    let resolutions = [];
    let scalesLength = MapUtils.scales.length;
    if (mobile) {
      // smallest resolution has problems rendering on mobile
      scalesLength--;
    }
    for (var i = 0; i < scalesLength; i++) {
      resolutions.push(MapUtils.getResolutionForScale(MapUtils.scales[i]));
    }
    const extent = MapConstants.extent;
    let options = {
      view: new ol.View({
        projection: MapUtils.projection,
        extent,
        enableRotation: false,
        constrainResolution: true,
        resolutions
      }),
      interactions: defaultInteractions({
        dragAndDrop: !disablePan,
        dragPan: !disablePan,
        keyboardPan: !disablePan,
        mouseWheelZoom: !disablePan,
      }),
      layers: [],
      controls: [],
      overlays: [],
      maxTilesLoading: 5
    };
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);

    setMap(mapObject);
    return () => {
      const view = mapObject.getView();
      let center = view.getCenter() !== undefined ? view.getCenter() : MapConstants.initialCenter;
      let zoom = view.getZoom() !== undefined ? view.getZoom() : MapConstants.initialZoom;
      dispatch(setCenterZoom(center, zoom));
      mapObject.setTarget(undefined);
    }
  }, [dispatch, disablePan, mobile]);

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [map, zoom]);

  // center change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [map, center]);

  // extent change handler
  useEffect(() => {
    if (!map || !extent) return;
    const mapSize = map.getSize();
    map.getView().fit(extent, {
      nearest: false,
      size: mapSize,
      maxZoom: 9
    });
  }, [map, extent]);

  useEffect(() => {
    map && map.updateSize();
  });

  const anyDrawerOpen = () => {
    return !mobile && (layerDrawerOpen || searchDrawerOpen || eraldisOpen || teatisOpen || mkeOpen || mueOpen);
  };

  const mapHeight = () => {
    if (!mobile) {
      return window.innerHeight - 102;
    } else {
      return window.innerHeight - 56;
    }
  };

  return (
    <MapContext.Provider value={{ map }} >
      <MapDiv ref={mapRef} sx={{ height: mapHeight()+'px', ...(anyDrawerOpen() && { width: 'calc(100vw - 425px)', marginLeft: '425px' }) }}>
        {children}
      </MapDiv>
    </MapContext.Provider>
  )
}
export default Map;