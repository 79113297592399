import OLGeoJSON from 'ol/format/GeoJSON';
import { boundingExtent, createEmpty, extend, getCenter } from 'ol/extent';
import { toggleFeaturesDialog } from '../stores/map/feature';
import { setActiveGeometries } from '../stores/map/map';
import ImageWMSLayer from '../components/map/layers/ImageWMSLayer';
import { WKT } from 'ol/format';
import GeneralUtils from './GeneralUtils';
import * as olProj from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { Geolocation } from 'ol';
import { point } from "@turf/helpers";

proj4.defs("EPSG:3301", "+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
register(proj4);

const geoJsonFormat = new OLGeoJSON();
const MapUtils = {
  projection: olProj.get('EPSG:3301'),
  mapDpi: 200,
  dpi: 25.4 / 0.28,
  inchPerMeter: 100 / 2.54,
  scales: [
    2000000,
    500000,
    250000,
    100000,
    50000,
    20000,
    10000,
    5000,
    2000,
    1000,
    500,
    200,
    100
  ],
  geometriesToFeatures(geometries, style) {
    if (!geometries?.length) {
      return [];
    }
    return geometries.map(geometry => this.geometryToFeature(geometry, style));
  },
  geometryToFeature(geometry, style) {
    let feature = { type: "Feature", geometry };
    if (style) {
      feature.style = style;
    }
    return feature;
  },
  geometryToOLFeature(geometry) {
    if (!geometry) {
      return null;
    }
    return geoJsonFormat.readFeature(this.geometryToFeature(geometry));
  },
  toOLGeometry(geometry) {
    return geoJsonFormat.readGeometry(geometry);
  },
  toOLFeatures(features) {
    const featureCollection = features.length === 1 ? features[0] : {
      type: "FeatureCollection",
      features: features
    };

    return geoJsonFormat.readFeatures(featureCollection);
  },
  toOLFeature(feature) {
    return geoJsonFormat.readFeature(feature);
  },
  getFeaturesExtent(features, bufferSize) {
    let extent = createEmpty();
    features.filter(feature => feature.getGeometry()).forEach(feature => extend(extent, feature.getGeometry().getExtent()));
    if (bufferSize) {
      let center = getCenter(extent);
      center[1] -= bufferSize;
      extend(extent, boundingExtent([center]));
    }
    return extent;
  },
  getExtentCenter(extent) {
    return getCenter(extent);
  },
  toGeoJSONFeatures(olFeatures) {
    if (!olFeatures) {
      return [];
    }
    return geoJsonFormat.writeFeaturesObject(olFeatures).features;
  },
  toGeoJSONGeometry(olFeature) {
    if (!olFeature || !olFeature.getGeometry()) {
      return null;
    }
    return geoJsonFormat.writeGeometryObject(olFeature.getGeometry());
  },
  toGeoJSONFeature(olFeature) {
    if (!olFeature || !olFeature.getGeometry()) {
      return null;
    }
    return geoJsonFormat.writeFeatureObject(olFeature);
  },
  handleLayerDrawerOpenClassName(container, open) {
    if (container) {
      if (open) {
        container.className += ' layer-drawer-open';
      } else {
        container.className = container.className.replace('layer-drawer-open', '');
      }
    }
  },
  showOnMap: (history, geom) => async dispatch => {
    dispatch(toggleFeaturesDialog(false));
    const geomArray = Array.isArray(geom) ? geom : [geom];
    dispatch(setActiveGeometries(geomArray));
    history.push(`/`);
  },
  getLegendGraphicUrl(layer, size, forceLabels) {
    if (!size) {
      size = 16;
    }
    let legendOptions = 'fontAntiAliasing:true;fontName:Roboto;fontSize:12;fontColor:0x091E42;labelMargin:10;';
    if (!!forceLabels) {
      legendOptions += 'forceLabels:on;';
    } else {
      legendOptions += 'forceLabels:off';
    }
    let url = layer.server.url;
    if (!layer.server.url.endsWith('&')) {
      url += '?';
    }
    url += 'SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&';
    url += `WIDTH=${size}&HEIGHT=${size}&LAYER=${layer.kihiNimi}&STYLE=${layer.stiilid || ''}&LEGEND_OPTIONS=${legendOptions}`;
    return url;
  },
  parseFeatures(json) {
    if (!json) {
      return [];
    }
    let features = [];
    Object.keys(json).forEach(key => {
      if (Array.isArray(json[key])) {
        json[key].forEach(origFeature => {
          features.push(this.parseSingleFeature(origFeature));
        });
      } else if (key.endsWith('_feature')) {
        features.push(this.parseSingleFeature(json[key]));
      } else if (typeof json[key] === 'object') {
        features = [...features, ...this.parseFeatures(json[key])];
      }
    });
    return features;
  },
  parseSingleFeature(origFeature) {
    let feature = {};
    Object.keys(origFeature).filter(key => !key.startsWith('gml:')).forEach(key => feature[this.prettyfyKey(key)] = origFeature[key]);
    return feature;
  },
  prettyfyKey(text) {
    if (!text) {
      return '';
    }
    return (text.charAt(0).toUpperCase() + text.toLowerCase().slice(1)).replace(/_/g, ' ');
  },
  getResolutionForScale(scale) {
    return parseFloat(scale) / (this.inchPerMeter * this.dpi);
  },
  getScaleForResolution(resolution) {
    return Math.round(parseFloat(resolution) * this.inchPerMeter * this.dpi);
  },
  getWMSLayer(layer, index, queryable, cqlFilter, zIndex) {
    return <ImageWMSLayer key={index} url={layer.server.url} layers={layer.kihiNimi} zIndex={zIndex || layer.zIndex}
      queryable={queryable} title={layer.nimetus} layerLayerName={layer.layerLayerName} cqlFilter={cqlFilter} styles={layer.stiilid} />
  },
  toWkt(geoJsonGeometries) {
    return geoJsonGeometries.map(geometry => {
      const olGeometry = geoJsonFormat.readGeometry(geometry);
      return new WKT().writeGeometry(olGeometry);
    });
  },
  copyToClipboard(text) {
    if (!navigator.clipboard) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    } else {
      navigator.clipboard.writeText(text);
    }
  },
  convertToRgbString(color) {
    return !!color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : '';
  },
  formatArea: (area) => {
    let output, unit;
    if (area > 10000000) {
      output = area / 1000000;
      unit = 'km\u00B2';
    } else if (area > 10000) {
      output = area / 10000;
      unit = 'ha';
    } else {
      output = area;
      unit = 'm\u00B2';
    }
    return `${GeneralUtils.formatNumber(Math.round(output * 100) / 100)} ${unit}`;
  },
  getLocation: (onSuccess, onError) => {
    const geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: MapUtils.projection
    });
    geolocation.on('change', function (evt) {
      const position = geolocation.getPosition();
      if (position) {
        onSuccess(point(position));
        geolocation.setTracking(false);
      }
    });
    geolocation.on('error', function (error) {
      onError(error.message);
      geolocation.setTracking(false);
    });
  },
  getViewparams: (styles) => {
    let filteredStyles = {};
    for (var k = styles.length - 1; k >= 0; k--) {
      if (styles[k]) {
        checkStyle(filteredStyles, styles[k]);
        if (styles[k].kasutajaValitud) {
          styles.splice(k++, 1);
        }
      }
    };
    if (filteredStyles.vali_objekt === undefined) {
      filteredStyles.vali_objekt = true;
    }
    if (!filteredStyles.fill_opacity) {
      filteredStyles.fill_opacity = 0;
    }
    if (!filteredStyles.stroke_width) {
      filteredStyles.stroke_width = 1;
    }

    var params = '';
    for (var type in filteredStyles) {
      var value = filteredStyles[type];
      if (value != null) {
        params += type + ':' + value + ';';
      }
    }
    return params;
  },
};

export default MapUtils;

function checkStyle(styles, selectedStyle) {
  if (selectedStyle.kasutajaValitud || (selectedStyle.vaikimisiValitud && !styles[selectedStyle.liik])) {
    if (!isNaN(selectedStyle.vaartus)) {
      selectedStyle.vaartus = parseFloat(selectedStyle.vaartus);
    }
    else if (selectedStyle.vaartus === 'true') {
      selectedStyle.vaartus = true;
    }
    else if (selectedStyle.vaartus === 'false') {
      selectedStyle.vaartus = false;
    }
    styles[selectedStyle.liik] = selectedStyle.vaartus;
  }
}