import {TextField} from "@mui/material";
import {styled} from "@mui/system";
import {Controller} from "react-hook-form";

const StyledSearchField = styled(TextField)(({
    width: '95%',
    paddingBottom: '0.5em',
}));

export const getPatternValue = (pattern) => {
    if (pattern === 'cadastre') {
        return {value:/\d{5}:\d{3}:\d{4}/, message: "Vigane katastri number (xxxxx:xxx:xxxx)"};
    }
    if (pattern === 'number') {
        return {value:/^\d+$/, message: "Väli võib sisaldada ainult numbreid"};
    }
    return pattern;
};

export default function SearchTextField({ name, control, label, pattern, minLength, maxLength, errors, error, helpertext, required }) {
    let rules = {};
    if (required) {
        rules.required = {value: true}
    }
    if (minLength) {
        rules.minLength = { value: minLength, message: 'Liiga lühike sisend'}
    }
    if (maxLength) {
        rules.maxLength = { value: maxLength, message: 'Liiga pikk sisend' }
    }
    if (pattern) {
        rules.pattern = { value: getPatternValue(pattern).value, message: getPatternValue(pattern).message }
    }
    if (!error) {
        error = !!(errors && errors[name]);
    }
    if (!helpertext) {
        helpertext = errors && errors[name] && errors[name].message;
    }

    return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={( {
                    field: { onChange, value},
                  }) => (
                    <StyledSearchField
                        onChange={onChange}
                        value={value || ''}
                        label={label}
                        error={error}
                        helperText={helpertext}
                    />
                )}
            />
    )
}