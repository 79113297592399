import { useContext, useEffect, useRef } from "react";
import OLControl from "ol/control/Control";
import MapContext from "../MapContext";
import { useDispatch, useSelector } from "react-redux";
import { toggleActiveControl } from "../../../stores/map/map";
import { IconButton } from "@mui/material";
import { LayersOutlined, Search, Undo, Straighten, MyLocation, QuestionMark } from "@mui/icons-material";

const MapControlButton = ({ controlName, children, controlActive, onClick, tooltipPlacement, iconName, title }) => {
  const { map } = useContext(MapContext);
  const dispatch = useDispatch();
  const { activeControl, layerDrawerOpen } = useSelector(state => state.map);
  const eraldisOpen = useSelector(state => state.eraldis.detailsOpen);
  const teatisOpen = useSelector(state => state.teatis.detailsOpen);
  const mkeOpen = useSelector(state => state.mke.detailsOpen);
  const mueOpen = useSelector(state => state.mue.detailsOpen);
  const { searchDrawerOpen } = useSelector(state => state.search);
  const ref = useRef(null);

  const active = controlActive !== undefined ? controlActive : activeControl === controlName;

  useEffect(() => {
    if (!map || !ref) return;

    const control = new OLControl({ element: ref.current });

    map.controls.push(control);

    return () => map.controls.remove(control);
  }, [dispatch, map, ref]);

  const handleToggleControl = () => {
    if (onClick) {
      return onClick();
    }
    dispatch(toggleActiveControl(controlName));
  };

  const anyDrawerOpen = () => {
    return layerDrawerOpen || searchDrawerOpen || eraldisOpen || teatisOpen || mkeOpen || mueOpen;
  };

  const drawerOpenClass = anyDrawerOpen() && !tooltipPlacement ? 'drawer-open' : '';

  const icons = {
    LayersOutlined: LayersOutlined,
    MyLocation: MyLocation,
    QuestionMark: QuestionMark,
    Search: Search,
    Straighten: Straighten,
    Undo: Undo
  };

  let getIcon = () => {
    const Icon = icons[iconName];
    return Icon ? (<Icon />) : null;
  }

  return <div>
    <div ref={ref} className={`ol-control ol-${controlName} ${drawerOpenClass}`}>
      <div title={title} placement={tooltipPlacement || 'right'} onClick={handleToggleControl}>
        <IconButton className={active ? 'active' : ''}>
            { getIcon() }
        </IconButton>
      </div>
      {active && children}
    </div>
  </div>;
};

export default MapControlButton;