import './App.css';
import { createTheme, ThemeProvider, CssBaseline, Slide, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { fetchClassifiers, fetchCounties, fetchMunicipalities } from './stores/classifier';
import { etEE } from '@mui/material/locale';
import ReactGA from 'react-ga4';
import { fetchLayers, toggleLayerDrawer } from './stores/map/map';
import { changeViewMode } from './stores/global';
import { CenteredLoadingIndicator } from './components/form/LoadingIndicator';
import { fetchActiveNotifications, hideNotification } from './stores/notification';
import { Colors } from './components/style/Colors';
import { Notification } from './components/form/Notification';
import ScrollToTop from './components/table/ScrollToTop';
import Header from './parts/Header';
import MainMap from './pages/MainMap';
import Shadows from './components/style/Shadows';
import MueDetails from './components/details/MueDetails';
import Info from './pages/Info';
import Search from './pages/Search';
import MkeDetails from './components/details/MkeDetails';
import EraldisDetails from './components/details/EraldisDetails';
import TeatisDetails from './components/details/TeatisDetails';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.sapphireBlue,
    },
    secondary: {
      main: Colors.sapphireBlue18,
    },
    error: {
      main: Colors.jasper
    },
    warning: {
      main: Colors.darkTangerine10
    },
    info: {
      main: Colors.sapphireBlue4
    },
    success: {
      main: Colors.seaGreen
    },
    background: {
      default: Colors.blackCoral0
    },
    text: {
      primary: Colors.sapphireBlue18
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          margin: 0,
          overflowY: 'hidden',
        },
        body: {
          fontSize: 14,
          letterSpacing: 0.4,
          lineHeight: '24px',
          overflowY: 'hidden',
          height: '100%',
          margin: 0,
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        },
        a: {
          textDecoration: 'none',
          color: Colors.sapphireBlue
        },
        '*::-webkit-scrollbar': {
          width: '0.4em'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.withOpacity(Colors.blackCoral, 0.5),
          outline: '1px solid slategrey'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: 'transparent',
            color: Colors.sapphireBlue
          },
          '&$selected:hover': {
            backgroundColor: Colors.sapphireBlue
          }
        },
        button: {
          '&:hover': {
            backgroundColor: Colors.sapphireBlue
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: Shadows.shadow1
        },
        elevation2: {
          boxShadow: Shadows.shadow2
        },
        elevation24: {
          boxShadow: Shadows.shadow1
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.blackCoral1
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'none',
            opacity: 0.5,
            transition: 'all 0.2s ease-in-out',
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: Colors.withOpacity(Colors.seaGreen, 0.3)
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
  }
}, etEE);

function Notifications() {
  const dispatch = useDispatch();
  const { open, message, type, autoHide } = useSelector(state => state.notification);

  const handleClose = () => dispatch(hideNotification());

  return <Notification open={open} message={message} type={type} onClose={handleClose} autoHide={autoHide} TransitionComponent={Slide} />
}

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

function App() {
  const dispatch = useDispatch();
  const authenticated = false;
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    dispatch(fetchClassifiers());

    if (trackingId) {
      ReactGA.initialize(trackingId);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLayers());
  }, [dispatch, authenticated]);

  useEffect(() => {
    dispatch(changeViewMode(!desktop));
    dispatch(toggleLayerDrawer(desktop));
  }, [dispatch, desktop]);

  useEffect(() => {
    dispatch(fetchActiveNotifications());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCounties());
    dispatch(fetchMunicipalities());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<CenteredLoadingIndicator />}>
        <CssBaseline />
        <Notifications />
        <ScrollToTop />
        <Router basename='mobile'>
          <Header />
          <MainMap />
          <Routes>
            <Route path="/" element={<></>}/>
            <Route path="/mue/:id" element={<MueDetails />} />
            <Route path="/mke/:id" element={<MkeDetails />} />
            <Route path="/eraldis/:id" element={<EraldisDetails />} />
            <Route path="/teatis/:id" element={<TeatisDetails />} />
            <Route path="/info" element={<Info />} />
            <Route path="/otsi" element={<Search />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
