import {styled} from "@mui/system";
import {FormControlLabel, Checkbox} from "@mui/material";
import {Controller} from "react-hook-form";

const ControlLabel = styled(FormControlLabel)(({
    marginLeft: 1,
    width: '95%',
    paddingBottom: '0.5em',
}));

export default function SearchCheckbox({control, name, label}) {
    return <ControlLabel
        control={
            <Controller
                control={control}
                name={name}
                render={({field: {onChange, value}}) => (

                    <Checkbox
                        name={name}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                    />

                )}
            />
        }
        label={label}
    />
}