import React, {useRef, useState} from "react";
import {debounce} from "@mui/material";
import {styled} from "@mui/system";
import {Shadows} from '../style/Shadows';
import {useDispatch, useSelector} from "react-redux";
import {setActiveGeometries} from "../../stores/map/map";
import {transform} from "ol/proj";
import {clearFeatures} from "../../stores/map/feature";
import SearchForm from "../search/SearchForm";
import FormButtons from "../search/FormButtons";
import SubmitButton from "../search/SubmitButton";
import ClearButton from "../search/ClearButton";
import {toggleSearchDrawer} from "../../stores/search";

const CoordinateSearchForm = styled('div')(({
  width:"95%",
  borderRadius:8,
  margin:"0 0 1rem 0",
  padding:"0"
}));

const SearchBar = styled('input')(({ 
  borderRadius: 4,
  border:"0.1rem slategrey solid",
  height: 48,
  width: "100%",
  padding:"0 0.5rem",
  backgroundColor: 'white',
  boxShadow: Shadows.shadow2
}));

function translateCoordinates(input) {
  var coords = [];
  input = input.trim();
  input = input.replace(/\(\)/g);
  if ((input.match(/\s/g) || []).length > 1) {
    //kui mitu tyhikut siis asendame need koik
    input = input.replace(/\s/g, '');
  }
  var currentValue = '';
  var deg = '';
  var min = '';
  var sec = '';
  var degMinSecMode = /°|'|"|’/.test(input);
  for (var i = 0; i < input.length; i++) {
    var char = input[i];
    if (degMinSecMode) {
      if (/^\d$/.test(char)) {
        if (!currentValue) {
          deg += char;
        }
        else if (currentValue.indexOf('.') === -1) {
          min += char;
        }
        else {
          sec += char;
        }
        continue;
      }

      if (/^°|'|"|’$/.test(char)) {
        //lat/lonile saab sisestada ka minutitena, vajab teisendamist
        if (deg) {
          currentValue += deg;
          deg = '';
        }
        else if (min) {
          currentValue = (parseFloat(currentValue) + parseFloat(min) / 60).toString();
          min = '';
        }
        else if (sec) {
          const valueLatLon = parseFloat(currentValue) + (parseFloat(sec) / 3600);
          sec = '';
          coords.push(valueLatLon);
          currentValue = '';
        }
        continue;
      }

      if (/^\.|,|\s|:|;$/.test(char)) {
        if (currentValue && !isNaN(currentValue)) {
          var valueXY;
          if (sec) {
            valueXY = parseFloat(currentValue) + (parseFloat(sec) / 3600);
            sec = '';
          }
          else {
            valueXY = parseFloat(currentValue);
          }
          coords.push(valueXY);
          currentValue = '';
        }
        continue;
      }
    }
    else {
      if (/^\d$/.test(char)) {
        currentValue += char;
        continue;
      }

      if (/^\.|,$/.test(char) && !/\.|,/.test(currentValue)) {
        currentValue += '.';
        continue;
      }

      if (/^\.|,|\s|:|;$/.test(char)) {
        if (currentValue && !isNaN(currentValue)) {
          coords.push(parseFloat(currentValue));
          currentValue = '';
        }
        continue;
      }
    }
  }
  if (currentValue && !isNaN(currentValue)) {
    var value;
    if (sec) {
      value = parseFloat(currentValue) + (parseFloat(sec) / 3600);
      sec = '';
    }
    else {
      value = parseFloat(currentValue);
    }
    coords.push(value);
  }
  if (coords.length === 1 && /\./.test(coords[0])) {
    var stringValue = coords[0].toString();
    coords = stringValue.split('.');
  }

  if (coords.length === 2) {
    var x, y, lat, lon;
    //kontrollime, et oleks ligikaudu eesti piires
    coords.forEach(function (c) {
      if (c >= 360000 && c <= 745000) {
        x = c;
      }
      else if (c >= 6375000 && c <= 6620000) {
        y = c;
      }
      else if (c > 20 && c < 29) {
        lon = c;
      }
      else if (c > 57 && c < 60) {
        lat = c;
      }
    });

    if (lat && lon) {
      return transform([lon, lat], 'EPSG:4326', 'EPSG:3301');
    }
    if (x && y) {
      return [x, y];
    }
  }
  return null;
}


export const MapSearch = () => {
  const { mobileView } = useSelector(state => state.global)
  const dispatch = useDispatch();
  const formEl = useRef(null);
  const [coordinateResult, setCoordinateResult] = useState(null);

  const handleSearch = debounce((value) => {
    if (coordinateResult) setCoordinateResult(null);
    const coordinates = translateCoordinates(value);
    if (coordinates) {
      setCoordinateResult({
        label: "Koordinaadid" + {coordinates: value},
        coordinates
      });
    }
  }, 0);

  const handleSelect = (address) => {
    dispatch(clearFeatures());

    if (address.coordinates) {
      mobileView && dispatch(toggleSearchDrawer());
      dispatch(setActiveGeometries([{
        type: 'Point',
        coordinates: address.coordinates
      }], false, !!address.bbox));
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    if (coordinateResult) {
      handleSelect(coordinateResult);
    }
  };


  const handleClear = () => {
    dispatch(clearFeatures());
    formEl.current.value = "";
    if (coordinateResult) setCoordinateResult(null);
  };

  const CoordinateSearch = () => {
    return  <>
              <CoordinateSearchForm>
                  <SearchBar ref={formEl} hideIcon placeholder='x,y | lat,lon' onInput={(e) => handleSearch(e.target.value)} minCharacters={3} />
              </CoordinateSearchForm>
              <FormButtons>
                <SubmitButton variant="contained" onClick={handleSubmit}>Otsi</SubmitButton>
                <ClearButton variant="outlined" onClick={handleClear}>Puhasta</ClearButton>
              </FormButtons>
            </ >;
  };

  return <SearchForm fields={CoordinateSearch()} />;
}