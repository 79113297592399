import {MenuItem, OutlinedInput, Select} from "@mui/material";
import {styled} from "@mui/system";
import {Controller} from "react-hook-form";

const SearchSelectField = styled(Select)(({
    width: '95%',
    paddingBottom: '0.5em',
}));

const SearchSelectInput = styled(OutlinedInput)(({
    padding:0,

}));

export default function SearchSelect({ name, placeholder, options, multiple, dataLabel, error, control, defaultValueName }) {
    return <Controller
            control={control}
            name={name}
            defaultValue=''
            render={({field}) => (
                <SearchSelectField
                    {...field}
                    MenuProps={{PaperProps: {sx: {maxHeight: 180,}}}}
                    sx={{paddingBottom: 0}}
                    displayEmpty
                    error={error}
                    multiple={multiple}
                    defaultValue=''
                    input={<SearchSelectInput/>}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <p style={{margin: 0, padding: 0, color: 'rgb(118, 118, 118)'}}>{placeholder}</p>;
                        }
                        return multiple ? selected.join(', ') : options.find(opt=> opt.kood === selected).nimetus;
                    }}
                    >
                    {!multiple && <MenuItem value="">
                                        <em style={{margin: 0, padding: 0, color: 'rgb(118, 118, 118)'}}>{defaultValueName}</em>
                                    </MenuItem>
                    }
                    {options.map((item, index) => (
                        <MenuItem
                            key={item.kood + `${index}`}
                            value={item.kood}
                        >
                            {item[dataLabel]}
                        </MenuItem>
                    ))}
                </SearchSelectField>
            )}
    />
}