import * as React from 'react';
import { useState } from 'react';
import { Box, FormControl, MenuItem, Select, Alert } from '@mui/material';
import { styled } from "@mui/system";
import LoadingIndicator from "../form/LoadingIndicator";
import ResultTree from './ResultTree';
import { useDispatch, useSelector } from 'react-redux';
import { rememberCurrentTab } from '../../stores/search';
import FormButtons from './FormButtons';
import SubmitButton from './SubmitButton';
import ClearButton from './ClearButton';

const Form = styled('form')(({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 12px',
    backgroundColor: '#fff'
}));

const SearchForm = ({ fields, form, isLoading, result, handleSubmit, fetchResults, resetResults, getSubTreeInfo, openDetails, showLocationForMain, showGeometries, showCadastre, expandedResults, toggleExpanded }) => {
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ validFields, setValidFields ] = useState(false);
    const { currentTab } = useSelector(state => state.search);
    const [label, setLabel] = useState(currentTab || 'EraldisForm');
    const dispatch = useDispatch();
    
    const handleChange = (e) => {
        setLabel(e.target.value);
        dispatch(rememberCurrentTab(e.target.value))
        setErrorMessage('');
        setValidFields(false);
    };
    
    const handleReset = () => {
        resetResults();
        form.reset();
        setErrorMessage('');
    }
    
    const validateSubmit = () => {
        setValidFields(false);
        let criteria = form.getValues();

        if (Object.values(criteria).every(obj => obj === '' || !obj || !obj.length)) {
            setErrorMessage('Ühtegi välja pole täidetud');
            return false;
        }
        else if (['EraldisForm', 'TeatisForm'].includes(label)) {
            if (!criteria.teatiseNr && !criteria.katastriNr && (!criteria.yksuseNimi && !criteria.kinnistuNimi) && !criteria.kvartaliNr && !criteria.kinnistuNr) {
                label === 'EraldisForm' && setErrorMessage('Katastriüksus, kinnistu nimi, kvartali number või kinnistu number peab olema täidetud');
                label === 'TeatisForm' && setErrorMessage('Katastriüksus, teatise nr, kinnistu nimi, kinnistu number või kvartali number peab olema täidetud');
                return false;
            }
        }
        setValidFields(true);
        return true;
    }

    const onSubmit = (data) => {
        if (!validateSubmit()) {
            return;
        }

        setErrorMessage('');
        fetchResults(data);
    };
    
    return (<Box sx={{ backgroundColor: '#ffffff'}} mx={'12px'} pb={'1rem'}>
                <Form key={label} onSubmit={ label !== 'MapSearch' && handleSubmit((data) => onSubmit(data))} >
                <FormControl sx={{padding:'0.5em 0em', width: '95%',}} fullWidth>
                    <Select value={label} onChange={handleChange}>
                        <MenuItem value={'EraldisForm'}>Metsaeraldised</MenuItem>
                        <MenuItem value={'TeatisForm'}>Metsateatised</MenuItem>
                        <MenuItem value={'MkeForm'}>Metsakaitseekspertiisid</MenuItem>
                        <MenuItem value={'MueForm'}>Metsauuendusekspertiisid</MenuItem>
                        <MenuItem value={'MapSearch'}>Koordinaatide otsing</MenuItem>
                    </Select>
                </FormControl>
                {fields}
                {errorMessage? <Alert sx={{width:'95%'}} severity="warning">{errorMessage}</Alert>: null}
                {label !== 'MapSearch' && <FormButtons>
                                            <SubmitButton variant="contained" type="submit">Otsi</SubmitButton>
                                            <ClearButton variant="outlined" onClick={() => handleReset(form.reset(form.defaultValues))}>Puhasta</ClearButton>
                                        </FormButtons>}
            </Form>
            {isLoading && <LoadingIndicator/>}
            {!isLoading && result?.map((res, index) => (
                <React.Fragment key={index}>
                    <ResultTree res={res}
                                resIndex={index} 
                                defaultExpanded={result.length === 1} 
                                getSubTreeInfo={getSubTreeInfo} 
                                openDetails={openDetails}
                                showLocationForMain={showLocationForMain}
                                showGeometries={showGeometries}
                                showCadastre={showCadastre}
                                expandedResults={expandedResults}
                                toggleExpanded={toggleExpanded}
                                />
                </React.Fragment>))}
            <Box mx={'20px'} pb={'1rem'}>
            {!isLoading && !result?.length && validFields? <Alert sx={{width:'100%'}} severity='info'>{'Ei leidnud andmeid'}</Alert> : null}
            </Box>
        </Box>
    )
};

export default SearchForm;