import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import OLTileLayer from "ol/layer/Tile";
import OLXYZSource from "ol/source/XYZ";
import OLTileGrid from "ol/tilegrid/TileGrid";


const tileExtent = [40500, 5993000, 1064500, 7017000];
const mapResolutions = [4000, 2000, 1000, 500, 250, 125, 62.5, 31.25, 15.625, 7.8125, 3.90625, 1.953125, 0.9765625, 0.48828125];
const mapTileGrid = new OLTileGrid({
	extent: tileExtent,
	resolutions: mapResolutions
});

const TMSLayer = ({ url, zIndex = 0 }) => {
	const { map } = useContext(MapContext);
	useEffect(() => {
		if (!map) return;

		let source;
		source = new OLXYZSource({
			projection: map.getView().getProjection(),
			tileGrid: mapTileGrid,
			url: url + "/{z}/{x}/{-y}.jpg",
			crossorigin: "Anonymous"
		});
		let tileLayer = new OLTileLayer({
			source,
			zIndex,
		});

		map.addLayer(tileLayer);

		return () => {
			if (map) {
				map.removeLayer(tileLayer);
			}
		};
	}, [map, url, zIndex]);

	return null;
};

export default TMSLayer;