import {
  isDate,
  parseISO,
  format,
} from 'date-fns';

const DateUtils = {
  formatTimestampToDate(value) {
    try {
      return format(this.parse(new Date(value)), 'dd.MM.yyyy');
    } catch (e) {
      return '';
    }
  },
  parse(value) {
    if (!value) {
      return null;
    }
    return isDate(value) ? value : parseISO(value);
  }
};
export default DateUtils;