import { clone } from "lodash";
import CircleStyle from "ol/style/Circle";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Colors from "../style/Colors";
import MapUtils from "../../utils/MapUtils";


const LayerStyles = {
  activeFeature: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.darkTangerine7, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.darkTangerine7,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.darkTangerine7,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.darkTangerine7, 0.3),
    }),
  }),
  activeFeatureSecondary: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.sapphireBlue, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.sapphireBlue,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.sapphireBlue,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.sapphireBlue, 0.3),
    }),
  }),
  activeFeatureTertiary: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.sapphireBlue4, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.sapphireBlue4,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.sapphireBlue4,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.sapphireBlue4, 0.3),
    }),
  }),
  activeNoFillFeature: new Style({
    image: new Circle({
      radius: 6,
      stroke: new Stroke({
        color: Colors.darkTangerine7,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.darkTangerine7,
      width: 6,
    }),
  }),
  //takes rgba object ({r: 153, g: 0, b: 239, a: 1})
  drawedFeatureWithCustomColor: (color) => {
    if (!color) {
      return LayerStyles.drawedFeature();
    }
    const solidColor = MapUtils.convertToRgbString(color);
    let lightColor = clone(color);
    lightColor.a = 0.3
    const lighterColor = MapUtils.convertToRgbString(lightColor);

    return new Style({
      image: new Circle({
        radius: 8,
        fill: new Fill({
          color: lighterColor
        }),
        stroke: new Stroke({
          color: solidColor,
          width: 5
        })
      }),
      stroke: new Stroke({
        color: solidColor,
        width: 5
      }),
      fill: new Fill({
        color: lighterColor,
      }),
    });
  },
  drawedFeature: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: Colors.withOpacity(Colors.active, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.active,
        width: 5
      })
    }),
    stroke: new Stroke({
      color: Colors.active,
      width: 5
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.active, 0.3),
    }),
  }),
  highlightFeature: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: Colors.withOpacity(Colors.orange, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.orange,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.orange,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.orange, 0.3),
    }),
  }),
  selectedFeature: new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({
        color: Colors.withOpacity(Colors.active, 0.4)
      }),
      stroke: new Stroke({
        color: Colors.active,
        width: 6
      })
    }),
    stroke: new Stroke({
      color: Colors.active,
      lineDash: [20, 10],
      width: 6
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.active, 0.4),
    }),
  }),
  selectingMeasuring: new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: Colors.withOpacity(Colors.sapphireBlue18, 0.5),
      lineDash: [10, 10],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: Colors.withOpacity(Colors.sapphireBlue18, 0.7)
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
    }),
  }),
  measure: new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: Colors.sapphireBlue18,
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: Colors.sapphireBlue18,
      }),
    }),
  }),
  textOnly(text) {
    return new Style({
      text: new Text({
        text: text,
        scale: 2,
        fill: new Fill({
          color: Colors.sapphireBlue18
        }),
        stroke: new Stroke({
          color: 'white',
          width: 2.5
        })
      })
    });
  }
}

export default LayerStyles;