import React, { useEffect } from 'react';
import { Table, TableBody, TableRow } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMueDetails, toggleMueDetails } from "../../stores/mue";
import { useParams } from 'react-router';
import DateUtils from '../../utils/DateUtils';
import CellWithoutGridline from '../table/CellWithoutGridline';
import Details from '../../pages/Details';

function MueDetailsTable() {
    const { baasandmed } = useSelector(state => state.classifier);
    const { selectedResult } = useSelector(state => state.mue);

    return  <>         
            <Table>
                <TableBody>
                  <TableRow>
                      <CellWithoutGridline content="Ekspertiisi kp" />
                      <CellWithoutGridline content={DateUtils.formatTimestampToDate(selectedResult.teatisKinnitamisKp)} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Maakond" />
                      <CellWithoutGridline content={selectedResult.maakond} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Vald" />
                      <CellWithoutGridline content={selectedResult.vald} />
                  </TableRow>
                  {selectedResult.metskond &&
                    <TableRow>
                        <CellWithoutGridline content="Metskond" />
                        <CellWithoutGridline content={selectedResult.metskond} />
                    </TableRow>
                    }
                  {selectedResult.kinnistuNr &&
                    <TableRow>
                        <CellWithoutGridline content="Kinnistu nr" />
                        <CellWithoutGridline content={selectedResult.kinnistuNr} />
                    </TableRow>
                  }
                  {selectedResult.kinnistuNimi &&
                    <TableRow>
                        <CellWithoutGridline content="Kinnistu nimi" />
                        <CellWithoutGridline content={selectedResult.kinnistuNimi} />
                    </TableRow>
                  }
                  <TableRow>
                      <CellWithoutGridline content="Katastritunnus" />
                      <CellWithoutGridline content={selectedResult.valitoo.katastriNr} />
                  </TableRow>
                  {selectedResult.valitoo.kvartaliNr &&
                    <TableRow>
                        <CellWithoutGridline content="Kvartal" />
                        <CellWithoutGridline content={selectedResult.valitoo.kvartaliNr} />
                    </TableRow>
                  }
                  <TableRow>
                      <CellWithoutGridline content="Eraldis" />
                      <CellWithoutGridline content={selectedResult.valitoo.eraldiseNr} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Otsus" />
                      <CellWithoutGridline content={baasandmed['MUE_OTSUS'][selectedResult.otsus]} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Töö pindala" />
                      <CellWithoutGridline content={selectedResult.valitoo.pindala} />
                  </TableRow>
                </TableBody>
            </Table>
            </>;
}

export default function MueDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { selectedResult, selectedGeometry, detailsOpen, isLoading } = useSelector(state => state.mue);

    const mueValitooId = selectedResult?.valitooId;
    const drawerTitle = "MUE akt nr " + selectedResult?.aktiNr;

    const shouldFetch = !mueValitooId || mueValitooId !== Number(id);

    useEffect(() => {
        if (shouldFetch) {
          dispatch(fetchMueDetails(id));
        } else {
            dispatch(toggleMueDetails(true));
        }
    }, [shouldFetch, id, dispatch]);
    
    const handleClose = () => {
       dispatch(toggleMueDetails(false));
      };     

    return  <Details 
                open={detailsOpen} 
                handleClose={handleClose} 
                title={drawerTitle} 
                loading={isLoading} 
                content={<MueDetailsTable selectedResult={selectedResult} />} 
                geometry={[selectedGeometry]}
                />;

}