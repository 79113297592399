import { Button } from "@mui/material";
import { styled } from "@mui/system";
import Colors from "../style/Colors";

const SubmitButton  = styled(Button)(({
    backgroundColor: Colors.sapphireBlue,
    borderRadius: "2rem",
    background: "#005AA3",
    color: "white",
    fontWeight: 'bold',
    minWidth: 92,
    '&:hover': {
        backgroundColor: Colors.blackCoral,
    },
}));

export default SubmitButton;