import * as React from 'react';
import { styled } from '@mui/system';
import { TableCell } from '@mui/material';

const TableCellWithoutGridline = styled(TableCell)(({
  borderBottom: 'none',
  verticalAlign: 'top',
  fontSize: '1rem',
  '&:first-of-type': { 
    fontWeight: 'bold',
    width: '50%'
  } 
}));


const CellWithoutGridline = ({content, colspan}) => {

  return <TableCellWithoutGridline colSpan={colspan}>
            {content}
          </TableCellWithoutGridline>;
};

export default CellWithoutGridline;