import { configureStore, combineReducers } from '@reduxjs/toolkit';
import classifier from './classifier';
import notification from './notification';
import map from './map/map';
import feature from './map/feature';
import search from './search';
import eraldis from './eraldis';
import teatis from './teatis';
import global from './global';
import mue from "./mue";
import mke from "./mke";
import info from './info';

const reducer = {
  classifier,
  notification,
  map,
  feature,
  search,
  eraldis,
  teatis,
  global,
  info,
  mue,
  mke
};

const store = configureStore({
  reducer: combineReducers(reducer)
});

export default store;