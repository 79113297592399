import MapControlButton from "./MapControlButton";
import { useDispatch } from "react-redux";
import { toggleInfoDrawer } from "../../../stores/info";
import { useNavigate } from "react-router-dom";

const InfoControl = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(toggleInfoDrawer());
    navigate(`/info`);
  };

  return <MapControlButton
    controlName="info"
    controlActive={false}
    iconName="QuestionMark"
    onClick={handleClick}
    title="Info"
    tooltipPlacement="left" />;
};

export default InfoControl;