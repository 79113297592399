import {createSlice} from '@reduxjs/toolkit';
import {api} from "../api";
import StoreUtils from '../utils/StoreUtils';

// Slice
const slice = createSlice({
    name: 'info',
    initialState: {
        error: false,
        infoDrawerOpen: false,
        helpText: null,
        legend: api.defaults.baseURL + 'baas/legend',
        manuals: null,
        termsAndConditions: null,
    },
    reducers: {
        hasError: (state, action) => {
            state.error = action.payload;
        },
        helpTextSuccess: (state, action) => {
            state.helpText = action.payload;
        },
        infoDrawerVisibilityChanged: (state) => {
            state.infoDrawerOpen = !state.infoDrawerOpen;
        },
        manualsSuccess: (state, action) => {
            state.manuals = action.payload;
        },
    },
});

export default slice.reducer;

// Actions

const { hasError, helpTextSuccess, infoDrawerVisibilityChanged, manualsSuccess } = slice.actions;

export const toggleInfoDrawer = () => async dispatch => {
    dispatch(infoDrawerVisibilityChanged());
};

export const fetchHelpText = () => async dispatch => {
    try {
    await api.get('baas/abi').then((response) => {
        dispatch(helpTextSuccess(response.data));
    });
    }
    catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    }
  };

export const fetchManuals = () => async dispatch => {
    try {
    await api.get('baas/juhendList').then((response) => {
        dispatch(manualsSuccess(response.data));
    });
    }
    catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    }
  };

export const fetchManual = (manual) => async dispatch => {
    try {
        window.open(api.defaults.baseURL + 'baas/juhend?juhendName=' + manual, '_blank', 'noopener,noreferrer');
    }
    catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    }
  };