import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { MapConstants } from '../../constants/mapConstants';
import MapUtils from '../../utils/MapUtils';
import StoreUtils from '../../utils/StoreUtils';
import { toggleEraldisDetails } from '../eraldis';
import { toggleMkeDetails } from '../mke';
import { toggleMueDetails } from '../mue';
import { toggleTeatisDetails } from '../teatis';

// Slice
const slice = createSlice({
  name: 'map',
  initialState: {
    layerDrawerOpen: false,
    minDrawerOpen: false,
    minData: null,
    zoom: MapConstants.initialZoom,
    center: MapConstants.initialCenter,
    extent: null,
    activeFeatures: [],
    layers: [],
    layerGroups: [],
    groupedWMSLayers: [],
    layersLoaded: false,
    activeControl: null,
    selectActive: true,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    minDrawerVisibilityChanged: (state, action) => {
      state.minDrawerOpen = (action.payload !== undefined ? action.payload : !state.minDrawerOpen);
    },
    addMinData: (state, action) => {
      state.minData = action.payload;
      state.minDrawerOpen = true;
    },
    layerDrawerVisibilityChanged: (state, action) => {
      state.layerDrawerOpen = (action.payload !== undefined ? action.payload : !state.layerDrawerOpen);
    },
    layerGroupOpenChanged: (state, action) => {
      const group = findLayerGroup(state, action.payload.id);
      group.open = !group.open;
    },
    layerVisibilityChanged: (state, action) => {
      const layer = action.payload;
      setLayerVisibility(state, layer, !layer.visible);
      groupLayers(state);
    },
    layerGroupVisibilityChanged: (state, action) => {
      const group = findLayerGroup(state, action.payload.id);
      setGroupVisibility(state, group, !group.visible);
      groupLayers(state);
    },
    activeControlChanged: (state, action) => {
      if (action.payload.active || (action.payload.active === undefined && state.activeControl !== action.payload.control)) {
        state.activeControl = action.payload.control;
        if (state.selectActive) {
          state.selectActive = false;
        }
      } else {
        state.activeControl = null;
        if (!state.selectActive) {
          state.selectActive = true;
        }
      }
    },
    layersSuccess: (state, action) => {
      let layers;
      if (action.payload?.data) {
        layers = action.payload?.data;
      }
      state.layerGroups = [];
      layers.forEach(layer => {
        layer.layerLayerName = layer['layerName'];

        let group = layer;
        group.visible = layer.peidetud;
        group.kihid.sort((a, b) => (a.jrk > b.jrk) ? 1 : -1);
        group.kihid.forEach(kiht => {
          kiht.title = kiht.nimetus;
          kiht.layerLayerName = kiht.kihiNimi;
          kiht.visible = kiht.vaikimisiKuvatud;
          kiht.mapGroup = {
            order: group.jrk,
            title: group.nimetus,
            id: group.id,
            domain: null,
            parentGroup: null
          };
          if (kiht.stiilMuudetav) {
            kiht.legendUrl = getKihtLegendUrl(kiht);
          }
          state.layers.push(kiht);
        });

        state.layerGroups.push({
          id: group.id,
          title: group.nimetus,
          domain: group.domain,
          order: group.jrk,
          visible: !!group.visible,
        });

      });
      state.layerGroups.sort((a, b) => (a.order > b.order) ? 1 : -1);
      let indexedLayers = state.layers.map((layer, index) => ({layer:layer,index:index}))

      state.layers = indexedLayers.sort((a, b) => {
        let idxA = a.index;
        let idxB = b.index;
        let valA = a.layer;
        let valB = b.layer;
        const aGroupOrder = valA.mapGroup.parentGroup?.order || valA.mapGroup.order;
        const bGroupOrder = valB.mapGroup.parentGroup?.order || valB.mapGroup.order;
        if (aGroupOrder === bGroupOrder) {
          let result = valA.jrk - valB.jrk;

          if (result > 0) {
            return idxB -idxA
          }
          if (result < 0) {
            return idxA - idxB;
          }
          return result;
        } else {
          return aGroupOrder - bGroupOrder;
        }
      }).map(layer => layer.layer);
      groupLayers(state);
      state.layersLoaded = true;
      state.isLoading = false;
    },
    activeFeaturesSuccess: (state, action) => {
      if (action.payload.clearAll) {
        state.activeFeatures = [];
      }
      if (action.payload.features?.length) {
        state.activeFeatures = state.activeFeatures.concat(action.payload.features);
        if (action.payload.center) {
          state.extent = MapUtils.getFeaturesExtent(MapUtils.toOLFeatures(state.activeFeatures), action.payload.bufferSize ? action.payload.bufferSize : 0);
        }
      }
    },
    viewSuccess: (state, action) => {
      if (action.payload.extent) {
        state.extent = action.payload.extent;
      } else {
        state.center = action.payload.center;
        state.zoom = action.payload.zoom;
        state.extent = null;
      }
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError,
  layerDrawerVisibilityChanged, minDrawerVisibilityChanged, layerGroupOpenChanged,
  layerVisibilityChanged, layerGroupVisibilityChanged,
  layersSuccess, activeControlChanged, activeFeaturesSuccess, viewSuccess, addMinData } = slice.actions;


export const toggleMinDrawer = (open) => async dispatch => {
  dispatch(minDrawerVisibilityChanged(open));
};

export const storeMinData = (data) => async dispatch => {
  dispatch(addMinData(data));
}  

export const toggleLayerDrawer = (open) => async dispatch => {
  dispatch(layerDrawerVisibilityChanged(open));
};

export const toggleLayerGroup = (group) => async dispatch => {
  dispatch(layerGroupOpenChanged(group));
};


export const toggleGroupVisibility = (group) => async dispatch => {
  dispatch(layerGroupVisibilityChanged(group));
};

export const toggleLayer = (layer) => async dispatch => {
  dispatch(layerVisibilityChanged(layer));
};

export const toggleActiveControl = (control, active) => async dispatch => {
  dispatch(activeControlChanged({ control, active }));
};

export const fetchLayers = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('kaart/kihid').then((response) => dispatch(layersSuccess({ data: response.data })))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setActiveGeometries = (geometries, append, dontCenter, style) => async dispatch => {
  dispatch(activeFeaturesSuccess({ features: MapUtils.geometriesToFeatures(geometries, style), clearAll: !append, center: !dontCenter }))
};

export const setActiveFeatures = (features, append, dontCenter, bufferSize) => async dispatch => {
  dispatch(activeFeaturesSuccess({ features: features, clearAll: !append, center: !dontCenter, bufferSize: bufferSize }));
};

export const setCenterZoom = (center, zoom) => async dispatch => {
  dispatch(viewSuccess({ center, zoom }));
};

export const centerFeatures = (features, bufferSize) => async dispatch => {
  dispatch(viewSuccess({ extent: MapUtils.getFeaturesExtent(features, bufferSize) }));
};

const setGroupVisibility = (state, group, visibility) => {
  state.layers.filter(l => l.mapGroup?.id === group.id).forEach(l => l.visible = visibility);
  group.visible = visibility;
};

const setLayerVisibility = (state, layer, visibility) => {
  state.layers.find(l => l.id === layer.id).visible = visibility;
  const group = findLayerGroup(state, layer.mapGroup?.id);
  if (group) {
    checkGroupVisibility(state, group);
  }
};

const checkGroupVisibility = (state, group) => {
  const visibility = state.layers.filter(l => l.mapGroup?.id === group.id).every(l => l.visible);
  if (visibility !== group.visible) {
    group.visible = visibility;
  }
};

const groupLayers = (state) => {
  const visibleLayers = state.layers.filter(l => l.visible && l.server.liik === 'WMS');
  const groupedLayers = visibleLayers.filter(l => !l.server.url.startsWith('/') || !l.mapGroup.parentGroup);
  state.groupedWMSLayers = groupedLayers;
};

const getKihtLegendUrl = (kiht) => {
  return kiht.server.url +
    (kiht.kasutaProxy ? '&' : '?') +
    'REQUEST=GetLegendGraphic' +
    '&VERSION=' + kiht.versioon +
    '&SERVICE=WMS' +
    '&FORMAT=' + kiht.formaat +
    '&LAYER=' + kiht.kihiNimi +
    '&RULE=main' +
    '&WIDTH=20&HEIGHT=20' +
    '&ENV=' + window.encodeURIComponent(MapUtils.getViewparams(kiht.stiilid));
};

const findLayerGroup = (state, id) => state.layerGroups.find(lg => lg.id === id);

export const toggleDetailDrawers = (eraldisOpen, teatisOpen, mkeOpen, mueOpen) => async dispatch => {
  if (eraldisOpen) {
    dispatch(toggleEraldisDetails(false));
  }
  if (teatisOpen) {
    dispatch(toggleTeatisDetails(false));
  }
  if (mkeOpen) {
    dispatch(toggleMkeDetails(false));
  }
  if (mueOpen) {
    dispatch(toggleMueDetails(false));
  }
}

export const fetchKatastriGeom = (katastriNr) => async dispatch => {
  try {
      await api.get(`kaart/katastrid?katastrid=${katastriNr}`).then((response) => {
          return dispatch(centerFeatures([MapUtils.geometryToOLFeature(JSON.parse(response.data))], 500));
      });
  }
  catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
  }
};
