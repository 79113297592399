import React from 'react';
import Layers from './layers/Layers';
import Controls from './controls/Controls';
import Map from './Map';
import TMSLayer from './layers/TMSLayer';
import ScaleLineControl from './controls/ScaleLineControl';
import MousePositionControl from './controls/MousePositionControl';
import LayerSwitcherControl from './controls/LayerSwitcherControl';
import LayerDrawer from './LayerDrawer';
import { useSelector } from 'react-redux';
import ImageWMSLayer from './layers/ImageWMSLayer';
import SelectFeatureControl from './controls/SelectFeatureControl';
import InfoControl from './controls/InfoControl';
import LocateControl from './controls/LocateControl';
import SearchControl from './controls/SearchControl';
import ActiveFeaturesLayer from './layers/ActiveFeaturesLayer';
import HighlightFeaturesLayer from './layers/HighlightFeaturesLayer';
import FeaturesDialog from './FeaturesDialog';
import MinDrawer from './MinDrawer';

export default function DefaultMap({ extraControls }) {
  const { layers, groupedWMSLayers, center, zoom, extent } = useSelector(state => state.map);

  const controls = [
    <SelectFeatureControl />,
    <LayerSwitcherControl />,
    <SearchControl />,
    <LocateControl />,
    <InfoControl />,
    <ScaleLineControl />,
    <MousePositionControl />,
  ];
  if (extraControls?.length) {
    controls.push(...extraControls);
  }

  return <>
    <Map center={center} zoom={zoom} extent={extent}>
      <Layers>
        {layers.filter(l => l.visible && l.server.liik === 'TMS').map((layer, index) => (
          <TMSLayer key={index} url={layer.server.url + "/" + layer.kihiNimi} zIndex={layer.zIndex} queryable={false} />
        ))}
        {groupedWMSLayers.map((layer, index) => (
          <ImageWMSLayer
            key={index}
            url={layer.server.url}
            layers={layer.kihiNimi}
            zIndex={layer.zIndex}
            queryable={layer.queryable}
            title={layer.nimetus}
            layerLayerName={layer.layerLayerName}
            styles={layer.stiilid}
            geometryName={layer.geomeetriaNimi}
            groupedLayers={layer.groupedLayers}
            wmsVersion={layer.versioon}
            sldBody={layer.sldBody}
            isMr={layer.onMrSisemine}
            maxRes={layer.maxRes}
          />
        ))}
        <ActiveFeaturesLayer />
        <HighlightFeaturesLayer />
      </Layers>
      <Controls>
        {controls.map((control, index) => <React.Fragment key={index}>{control}</React.Fragment>)}
      </Controls>
    </Map>
    <LayerDrawer />
    <MinDrawer />
    <FeaturesDialog />
  </>;
}