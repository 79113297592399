import { toggleDetailDrawers, toggleLayerDrawer } from "../../../stores/map/map";
import { toggleSearchDrawer } from "../../../stores/search";
import { useDispatch, useSelector } from "react-redux";
import MapControlButton from "./MapControlButton";

const LayerSwitcherControl = () => {
  const dispatch = useDispatch();
  const { layerDrawerOpen } = useSelector(state => state.map);
  const { searchDrawerOpen } = useSelector(state => state.search);
  const eraldisOpen = useSelector(state => state.eraldis.detailsOpen);
  const teatisOpen = useSelector(state => state.teatis.detailsOpen);
  const mkeOpen = useSelector(state => state.mke.detailsOpen);
  const mueOpen = useSelector(state => state.mue.detailsOpen);

  const handleClick = () => {
    if (searchDrawerOpen) {
      dispatch(toggleSearchDrawer());
    }
    dispatch(toggleDetailDrawers(eraldisOpen, teatisOpen, mkeOpen, mueOpen));
    dispatch(toggleLayerDrawer());
  };



  return <MapControlButton
    controlName="layer-switcher"
    controlActive={layerDrawerOpen}
    onClick={handleClick}
    title="Kaardikihid"
    iconName="LayersOutlined"/>;
};

export default LayerSwitcherControl;