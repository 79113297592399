import * as React from 'react';
import { Drawer } from '@mui/material';
import { useSelector } from 'react-redux';

const FeaturesDrawer = ({content, open}) => {
  const { layerDrawerOpen } = useSelector(state => state.map);
  const eraldisOpen = useSelector(state => state.eraldis.detailsOpen);
  const teatisOpen = useSelector(state => state.teatis.detailsOpen);
  const mkeOpen = useSelector(state => state.mke.detailsOpen);
  const mueOpen = useSelector(state => state.mue.detailsOpen);
  const { searchDrawerOpen } = useSelector(state => state.search);
    
  const anyDrawerOpen = () => {
    return layerDrawerOpen || searchDrawerOpen || eraldisOpen || teatisOpen || mkeOpen || mueOpen;
  };

  return <Drawer open={open} anchor="bottom" variant="persistent" PaperProps={{sx: {...(anyDrawerOpen() && {marginLeft: '425px'})}}} >
    {content}
    </Drawer>;
};

export default FeaturesDrawer;