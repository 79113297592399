import * as React from 'react';
import { styled } from '@mui/system';
import { Table } from '@mui/material';
import Colors from '../style/Colors';

const TableAllBorders = styled(Table)(({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: Colors.blackCoral1
}));


const TableWithBorders = ({content}) => {

  return <TableAllBorders>
            {content}
          </TableAllBorders>;
};

export default TableWithBorders;