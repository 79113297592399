import {createSlice} from "@reduxjs/toolkit";
import {api} from "../api";
import StoreUtils from "../utils/StoreUtils";
import GeneralUtils from "../utils/GeneralUtils";
import { addSelectedFeatures } from "./map/feature";
import { setActiveFeatures } from "./map/map";

const apiPath = "eraldis";

const slice = createSlice({
    name: 'eraldis',
    initialState: {
        result:[],
        isLoading:false,
        error: false,
        selectedResult: null,
        detailsOpen: false,
        searchValues: null,
        expandedResults: []
    },
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        rowsSuccess: (state, action) => {
            state.result = action.payload;
            state.expandedResults = [];
            state.isLoading = false;
        },
        detailSuccess: (state, action) => {
            state.selectedResult = action.payload;
            state.isLoading = false;
            state.detailsOpen = true;
        },  
        eraldisDetailsVisibilityChanged: (state, action) => {
            state.detailsOpen = (action.payload !== undefined ? action.payload : !state.detailsOpen);
        },
        storeSearchValues: (state, action) => {
            state.searchValues = action.payload;
        },
        toggleExpandedResults: (state, action) => {
            const result = action.payload;
            state.expandedResults.indexOf(result) > -1 ? state.expandedResults.splice(result, 1) : state.expandedResults.push(result);
        }
    },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, detailSuccess, eraldisDetailsVisibilityChanged, storeSearchValues, toggleExpandedResults } = slice.actions;

export const resetAllocationResults = () => async dispatch => {
    dispatch(rowsSuccess([]));
    dispatch(storeSearchValues(null));
};

export const toggleEraldisDetails = (open) => async dispatch => {
    dispatch(eraldisDetailsVisibilityChanged(open));
};

export const fetchEraldised = (params) => async dispatch => {
    dispatch(startLoading());

    const criteriaParam = GeneralUtils.convertParamsToUrl(params);
    try {
        await api.get(`${apiPath}/puu?${criteriaParam}`).then((response) => {
            dispatch(rowsSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const fetchEraldisDetails = (eraldisId) => async dispatch => {
    dispatch(startLoading());
    try {
        await api.get(`${apiPath}/detail/${eraldisId}`).then((response) => {
            dispatch(detailSuccess(response.data));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const saveSearchValues = (values) => async dispatch => {
    dispatch(storeSearchValues(values));
};

export const fetchSelectedEraldised = (ids, single) => async dispatch => {
    const params = single ? (["id=" + ids]) : ids.map(id => ("idList=" + id));
    try {
        await api.get(`${apiPath}/otsi?${params.join('&')}`).then((response) => {
            const selectedEraldised = response.data?.map(r => ({eraldisId: r.id, katastriNr: r.katastriNr, eraldiseNr: r.eraldiseNr, geometry:JSON.parse(r.alaGeoJson) ?? null, staatus: r.staatus }));
            dispatch(addSelectedFeatures(selectedEraldised));
            dispatch(setActiveFeatures(selectedEraldised.map(e => (e.geometry))));
        });
    }
    catch (e) {
        dispatch(StoreUtils.handleError(e, hasError));
    }
};

export const toggleExpanded = (result) => async dispatch => {
    dispatch(toggleExpandedResults(result));
};
