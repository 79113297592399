

import * as React from 'react';
import { styled } from '@mui/system';
import Colors from '../style/Colors';
import { ButtonGroup, IconButton } from '@mui/material';
import { Close, ArrowBackIos, LocationOnOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { centerFeatures, storeMinData } from '../../stores/map/map';
import StoreUtils from '../../utils/StoreUtils';
import { clearFeatures, setHighlightGeometries, toggleMinimizeFeaturesDialog } from '../../stores/map/feature';
import { toggleSearchDrawer } from '../../stores/search';
import { showWarning } from '../../stores/notification';
import { useNavigate } from 'react-router-dom';
import MapUtils from '../../utils/MapUtils';

const DrawerTitle = styled('header')(({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: Colors.extraLight,
  borderLeftWidth: 5, 
  borderLeftStyle: 'solid',
  marginLeft: '12px'
}));

const DrawerHeading = styled('h1')(({
  color: Colors.blackCoral12,
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1.4rem',
  lineHeight: '2rem',
  paddingLeft: '8px',
})); 

const DrawerHeader = ({ title, close, handleClose, geometry, leftBorder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useSelector(state => state.global.mobileView);

  let getIcon = () => {
    return close ? (<Close />) : (<ArrowBackIos />);
  }

  let getLocation = () => {
    if (geometry === undefined || geometry[0] === null) {
      dispatch(showWarning("Objekti geomeetriat ei leitud"));
    } else {
      dispatch(clearFeatures());
      dispatch(setHighlightGeometries(geometry));
      dispatch(centerFeatures([MapUtils.geometryToOLFeature(geometry[0])], 500));
      if (mobile) {
        handleClose();
        dispatch(storeMinData({title: title }));
        dispatch(toggleMinimizeFeaturesDialog(false));
        dispatch(toggleSearchDrawer(false));
        navigate(`/kaart`);
      }
    }
  }

  const closeDrawer = () => {
    handleClose();
    if ((!mobile && title ==='Otsing') || title === 'Kaardikihid') {
      navigate(`/`, { replace : true });
    } else {
      navigate(-1);
    }
  }

  return <DrawerTitle sx={{ borderLeftColor: StoreUtils.getLeftBorderColor(leftBorder) }}>
            <DrawerHeading>{title}</DrawerHeading>
            <ButtonGroup>
              {geometry && <IconButton onClick={getLocation}>
                  <LocationOnOutlined />
              </IconButton> }
              <IconButton onClick={() => closeDrawer()} aria-label="close">                          
                {getIcon()}
              </IconButton>
            </ButtonGroup>
          </DrawerTitle>;
};

export default DrawerHeader;