import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { et } from 'date-fns/locale';
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Today} from '@mui/icons-material';
import Colors from '../style/Colors';
import {Controller} from "react-hook-form";
import {parseISO} from "date-fns";

export default function SearchDatePicker({control, name, placeholder, toolbarTitle}) {
    return <Controller
        control={control}
        name={name}
        defaultValue={null}
        render={({field: {onChange, value}}) => (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={et}
                localeText={{okButtonLabel:'Sulge', clearButtonLabel:'Puhasta', todayButtonLabel:"Täna"}}
            >
                <MobileDatePicker
                    value={value? parseISO(value) : null}
                    toolbarTitle={toolbarTitle}
                    inputFormat="dd.MM.yyyy"
                    e
                    componentsProps={{
                        actionBar: {
                            actions: ["clear","accept","today"]
                        }
                    }}
                    onChange={value => onChange(value? value.toISOString() : null)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <Today sx={{color: Colors.blackCoral}}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />}
                />
            </LocalizationProvider>
        )}
    />
}