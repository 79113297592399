import React, { useEffect } from 'react';
import { styled } from "@mui/system";
import logoPortaal from '../assets/images/metsaregister.ee_sinine_EST.svg';
import { AppBar, Toolbar, IconButton, Link, Box } from '@mui/material';
import { Lock } from '@mui/icons-material';
import Shadows from '../components/style/Shadows';
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {cas} from "../api";

const Logo = styled(Link)(({ theme }) => ({
    lineHeight: '56px',
    height: '56px',
    [theme.breakpoints.up('md')]: {
      left: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      left: '1.5rem',
    },
}));

const ToolbarHeader = styled(Toolbar)(({ theme }) => ({
  backgroundColor: 'white',
  [theme.breakpoints.up('md')]: {
    height: 102
  },
  [theme.breakpoints.down('md')]: {
    height: 56
  },
  boxShadow: Shadows.shadow1,
  zIndex: theme.zIndex.drawer + 1
}));

const LogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 56
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '8px',
    height: 40
  },
}));

function MobileHeader({handleLogin}) {

  const handleOpenUserPanel = () => {
    handleLogin();
  };

  return <Box sx={{ width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
    <Logo component={NavLink} to="/" onClick={() => Location.reload()}>
        <LogoImage src={logoPortaal} alt="logo" />
    </Logo>
    <IconButton aria-label="login or open user panel" onClick={handleOpenUserPanel}>
      <Lock />
    </IconButton>
    </Box>;
}

export default function Header() {

  const location = useLocation();

  const authenticated = false;

  const handleLogin = () => {
    window.location.href = cas;
  };

  const homepageRoute = { to: "/", label: 'Kaart', exact: true };
  const userRoutes = [homepageRoute];

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location]);

  return (
    <AppBar elevation={0} color="transparent" position="static">
      <ToolbarHeader>
        <MobileHeader routes={userRoutes} authenticated={authenticated}
            handleLogin={handleLogin} />
      </ToolbarHeader>
    </AppBar>
  );
}