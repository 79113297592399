export const Colors = {

    active: 'rgba(127, 92, 242, 1)',
    sapphireBlue: 'rgba(0, 90, 163, 1)',
    sapphireBlue4: 'rgba(139, 180, 213, 1)',
    sapphireBlue18: 'rgba(0, 25, 45, 1)',
    blackCoral: 'rgba(93, 96, 113, 1)',
    blackCoral0: 'rgba(240, 240, 242, 1)',
    blackCoral1: 'rgba(225, 226, 229, 1)',
    blackCoral12: 'rgba(77, 79, 93, 1)',
    blackCoral20: 'rgba(9, 9, 11, 1)',
    extraLight: 'rgba(249, 249, 249, 1)',
    darkTangerine1: 'rgba(255, 241, 211, 1)',
    darkTangerine7: 'rgba(255, 201, 81, 1)',
    darkTangerine10: 'rgba(255, 181, 17, 1)',
    lavenderBlush: 'rgba(252, 238, 238, 1)',
    orange: 'rgba(255, 128, 0, 1)',
    jasper: 'rgba(215, 62, 62, 1)',
    mintCream: 'rgba(236, 244, 239, 1)',
    seaGreen: 'rgba(48, 134, 83, 1)',
    notificationText: 'rgba(26, 27, 31, 1)',
    withOpacity: (color, opacity) => {
      return color.replace(/1\)$/g, `${opacity})`);
    }
  };
  export default Colors;