import { transform } from "ol/proj";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHighlightGeometries } from "../../../stores/map/feature";
import { setCenterZoom } from "../../../stores/map/map";
import { showWarning } from "../../../stores/notification";
import MapControlButton from "./MapControlButton";
import Geolocation from "@react-native-community/geolocation";

const LocateControl = () => {
  const [locateActive, setLocateActive] = useState(false);
  const [watchId, setWatchId] = useState(null);
  const [position, setPosition] = useState(null);
  const { zoom } = useSelector(state => state.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (navigator.geolocation && locateActive) {
      setWatchId(Geolocation.watchPosition(
        position => {
          setPosition(position);
        },
        null,
        { enableHighAccuracy: true, maximumAge: 1000, timeout: 5000 }
      ));
    } else {
      setPosition(null);
    }
  }, [dispatch, locateActive]);

  useEffect(() => {
    if (position !== null) {
      const geom = { type: 'Point', coordinates: transform([position.coords.longitude, position.coords.latitude], 'EPSG:4326', 'EPSG:3301') };
      dispatch(setHighlightGeometries([geom])); 
      dispatch(setCenterZoom(geom.coordinates, zoom > 0 ? zoom : 5));
    } else {
      dispatch(setHighlightGeometries([]));
    }
  }, [dispatch, position, zoom])

  useEffect(() => {
    if (!locateActive && watchId !== null) {
      Geolocation.clearWatch(watchId); 
      setWatchId(null);
    }
  }, [watchId, locateActive])

  const handleClick = () => {
    if ("geolocation" in navigator) {
      setLocateActive(!locateActive);
    } else {
      dispatch(showWarning("Positsioneerimine pole võimalik, palun kontrolli seadeid"));
      setLocateActive(false);
    }
  }

  return <MapControlButton controlName="locate"
                            iconName="MyLocation"
                            title="Positsioneeri"
                            controlActive={locateActive}
                            onClick={handleClick}
  />
};

export default LocateControl;