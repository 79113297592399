import React, { useEffect } from 'react';
import { useDispatch, useSelector} from "react-redux";
import { toggleInfoDrawer, fetchManual, fetchManuals, fetchHelpText } from "../stores/info";
import { Box, Card, CardContent, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Close, DescriptionOutlined, KeyboardArrowRight, KeyboardArrowUp, PersonOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function InfoListItem(item) {
    const [open, setOpen] = React.useState(item.open);

    let toggleOpen = () => {
        setOpen(!open);
    }

    let getArrow = () => {
        return open ? (<KeyboardArrowUp />) : (<KeyboardArrowRight />);
    }

    return <>
        <ListItem sx={{color: '#005AA3'}} onClick={() => toggleOpen()}>
            <Box width={'8px'} mr={2}>{getArrow()}</Box>        
            <ListItemText>{item.name}</ListItemText>
        </ListItem>
        <Box mx={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {item.content}
            </Collapse>
        </Box>
    </>
}

function GetTermsAndConditions() {
    return <>
            <div> 
                <h4> Metsaregistri metsaportaali kasutamise üldtingimused</h4>
            </div>   
            <div>
                <h4>1. Üldsätted</h4>
                <p>1.1 Metsaportaali kasutustingimused reguleerivad portaali kasutaja (edaspidi <i>Kasutaja</i>) ja metsaressursi arvestuse riikliku registri volitatud töötlejate kui Portaali haldajate (edaspidi <i>Haldajad</i>) vahelisi õigussuhteid. </p>
                <p>1.2 Metsaportaal (edaspidi <i>Portaal</i>) on metsaressursi arvestuse riikliku registri avalik veebiteenus.</p>
                <p>1.3	Haldaja pakub Portaali kaudu teenuseid vastavalt õigusaktidele.</p>
                <p>1.4	Metsaressursi arvestuse riikliku registri põhimääruse alusel on metsaregistri volitatud töötlejateks ehk Haldajateks:</p>
                <p>&emsp;- metsainventeerimisandmete osas Keskkonnaagentuur,</p>
                <p>&emsp;- metsateatiste ning  metsauuendus- ja metsakaitseekspertiiside osas Keskkonnaamet,</p>
                <p>&emsp;- registri infotehnoloogilise haldamise osas Keskkonnaministeeriumi Infotehnoloogiakeskus.</p>
                <p>1.5	Portaali sisenemine ja Kasutaja tuvastamine toimub ID-kaardi, mobiil-ID või Smart-ID teenuse kaudu.</p>
                <p>1.6	Portaali kasutamisel nõustub Kasutaja Portaali kasutustingimustega ja kohustub neid täitma.</p>
                <br />
                <h4>2 Kasutaja õigused ja kohustused</h4>
                <p>2.1 Kasutaja on kohustatud:</p>
                <p>&emsp;- järgima Portaali kasutamisel kõiki kohalduvaid õigusakte;</p>
                <p>&emsp;- kasutama Portaali kaudu saadud andmeid üksnes headele kommetele vastavatel eesmärkidel;</p>
                <p>&emsp;- esitama tõeseid andmeid: kasutaja esitatud andmete õigsuse eest vastutab Kasutaja.</p>
                <p>2.2 Kasutajal on keelatud:</p>
                <p>&emsp;- edastada Portaali kaudu saadud andmeid kolmandatele isikutele, välja arvatud õigusaktidega sätestatud juhul;</p>
                <p>&emsp;- teenust kasutada viisil, mis häirib või takistab Teenuste osutamist Haldaja klientidele või võivad seda teha.</p>
                <p>2.3 Teenuse kasutamine automatiseeritud programmide või tööriistadega tuleb kokku leppida Haldajaga.</p>
                <p>2.4 Kasutajal on õigus: </p>
                <p>&emsp;- kasutada Portaali kooskõlas Portaali kasutustingimustega;</p>
                <p>&emsp;- esitada Portaali kasutamist puudutavaid ettepanekuid ja pretensioone e-posti teel metsainventeerimisandmete osas aadressil <a href="mailto:klienditugi@envir.ee">klienditugi@envir.ee</a> ning metsateatiste ja  metsauuendus- ja metsakaitseekspertiiside osas aadressil <a href="mailto:klienditugi@keskkonnaamet.ee">klienditugi@keskkonnaamet.ee</a>.</p>
                <br />
                <h4>3 Haldaja õigused ja kohustused</h4>
                <p>3.1	Haldaja kohustub töötlema kasutaja isikuandmeid vastavalt isikuandmete kaitseseadusele ja mitte edastama neid kolmandatele isikutele, välja arvatud juhul, kuiõigusaktidega sätestatakse teisiti. Haldaja võib kasutada Kasutaja kontaktandmeid Haldaja tegevustega seotud teavituste edastamiseks ja teenuste kvaliteedi hindamiseks.</p>
                <p>3.2	Haldajal on õigus ajutiselt või alaliselt piirata Kasutaja ligipääsu Portaalile, kui Haldaja on tuvastanud Kasutaja poolt Portaali kasutustingimuste rikkumise või Portaali kasutamise viisil, mis on vastuolus  õigusaktidega või hea tavaga.</p>
                <p>3.3	Haldajal on õigus Portaali kasutustingimusi ühepoolselt muuta, avaldades muudatuse Portaalis vähemalt üks kuu enne selle jõustumist.</p>
                <p>3.4	Haldajal on õigus teha muudatusi Portaalis ilma sellest Kasutajat teavitamata.</p>
                <p>3.5	Haldajal on kohustus tagada Portaali kaudu saadud andmete turvalisus.</p>
                <p>3.6 Haldajal on kohustus säilitada Kasutaja poolt Portaali kaudu esitatud andmeid vastavalt õigusaktidele.</p>
                <br />
                <h4>4 Autoriõigused</h4>
                <p>4.1	Portaali sisu (sealhulgas graafika, logod, tekst ikoonid, pildid) ja tarkvara on kaitstud õigusaktidega.</p>
                <p>4.2	Portaalis olevaid eespool nimetatud andmeid ei ole lubatud kopeerida, paljundada, edasi anda ega mingil muul viisil kasutada ilma Haldaja kirjaliku nõusolekuta.</p>
                <p>4.3	Juurdepääs metsaregistrisse kantud andmetele ja registrist andmete väljastamine on sätestatud „Metsaressursi arvestuse riikliku registri põhimääruses“ <a href="https://www.riigiteataja.ee/akt/129082017002" target="_blank" rel="noreferrer">(RT I, 29.08.2017, 2)</a>.</p>
                <br />
                <h4>5 Lõppsätted</h4>
                <p>5.1	Käesolevad üldtingimused kehtivad tähtajatult.</p>
                <p>5.2	Käesolevate üldtingimuste alusel tekkinud vaidlused lahendavad Kasutaja ja Haldaja läbirääkimiste teel. Kokkuleppe mittesaavutamisel nimetatud viisil lahendatakse vaidlused kohtus Eesti Vabariigi õigusaktidega sätestatud korras.</p>
                <p>5.3.	Käesolevate üldtingimustega võetud kohustuste täitmata jätmise või mittenõuetekohase täitmise eest vastutavad Kasutaja ja Haldaja käesolevate üldtingimuste ja Eesti Vabariigi õigusaktidega sätestatud korras.</p>
            </div>
    </>;
}

function GetManuals() {
    const dispatch = useDispatch();
    const { manuals } = useSelector(state => state.info); 
    useEffect(() => {  
        if (!manuals) {dispatch(fetchManuals()) }
    }, [manuals, dispatch]);
    const selectManual = (manual) => { dispatch(fetchManual(manual)) };
    const renderManual = (manual) => {
        return <ListItem sx={{color: '#005AA3'}} onClick={() => selectManual(manual)}>
                <ListItemText>{manual}</ListItemText>
            </ListItem>
    } 
    if (manuals !== null && manuals.length > 0) {
        return <List dense>
                {manuals.map((manual, index) => <React.Fragment key={index}>
                    { renderManual(manual) }
                    </React.Fragment> )}
            </List>
    }
    return null;
}

export default function Info() {
    const navigate = useNavigate();
    const { legend, helpText, infoDrawerOpen } = useSelector(state => state.info);
    const mobile = useSelector(state => state.global.mobileView); 
    const dispatch = useDispatch();

    useEffect(() => {
        if (!helpText) { dispatch(fetchHelpText()) }
    }, [helpText, dispatch]);

    const handleClose = () => {
      dispatch(toggleInfoDrawer());
      navigate(-1);
    };
    
    const infoHeight = () => {
        if (!mobile) {
        return window.innerHeight - 102;
        } else {
        return window.innerHeight - 56;
        }
    };

    return <>
        <Drawer PaperProps={{sx: { 
                                    height: infoHeight()+'px',
                                    width: {md: '50%'}
                                    }}} anchor="bottom" onClose={handleClose} open={infoDrawerOpen}>
            <IconButton disableRipple={true} onClick={handleClose} aria-label="close">
                <Close />
            </IconButton>
            <Box ml={3}>
                <List dense>
                    <InfoListItem name='Kasutustingimused' content={GetTermsAndConditions()} open={false} />
                    <InfoListItem name='Juhendid' content={GetManuals()} open={false}/>
                    <ListItem sx={{color: '#005AA3'}}>
                        <Box width={'8px'} mr={2}>
                            <DescriptionOutlined fontSize='small' />
                        </Box>
                        <a href = {legend} target = "_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>Kaardilegend</a>
                    </ListItem>
                </List>
            </Box>
            <Box m={5}>
                <Card variant="outlined" sx={{background: '#FDFDFD'}}>
                    <CardContent>
                        <Typography sx={{display: 'flex'}} color="text.secondary">
                            <PersonOutline />
                            <span style={{ marginLeft: '0.5em' }}>ABI</span>
                        </Typography>
                        <Box mb={2}>
                            <Divider />
                        </Box>
                        <Typography sx={{whiteSpace: 'pre-wrap'}}>
                            {helpText}
                        </Typography>
                        <Divider />
                    </CardContent>
                </Card>
            </Box>
        </Drawer>
    </>;
  }