import React, { useEffect } from 'react';
import { Table, TableBody, TableRow } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import DateUtils from '../../utils/DateUtils';
import CellWithoutGridline from '../table/CellWithoutGridline';
import Details from '../../pages/Details';
import { fetchTeatisDetails, fetchTeatisGeometry, toggleTeatisDetails } from '../../stores/teatis';
import DetailInfoButton from './DetailInfoButton';
import FullWidthCell from '../table/FullWidthCell';

function TeatisDetailsTable() {
    const { baasandmed } = useSelector(state => state.classifier);
    const { selectedResult } = useSelector(state => state.teatis);
    const navigate = useNavigate();

    return  <>
            <DetailInfoButton title="Näita eraldist" handleClick={() => navigate(`/eraldis/${selectedResult.eraldiseId}`)} />                
            <Table>
                <TableBody>
                  <TableRow>
                      <CellWithoutGridline content="Esitamise kp" />
                      <CellWithoutGridline content={DateUtils.formatTimestampToDate(selectedResult.registreerimiseKp)} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Maakond" />
                      <CellWithoutGridline content={selectedResult.maakond} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Vald" />
                      <CellWithoutGridline content={selectedResult.vald} />
                  </TableRow>
                  {selectedResult.metskond &&
                    <TableRow>
                        <CellWithoutGridline content="Metskond" />
                        <CellWithoutGridline content={selectedResult.metskond} />
                    </TableRow>
                    }
                  {selectedResult.kinnistuNr &&
                    <TableRow>
                        <CellWithoutGridline content="Kinnistu nr" />
                        <CellWithoutGridline content={selectedResult.kinnistuNr} />
                    </TableRow>
                  }
                  {selectedResult.kinnistuNimi &&
                    <TableRow>
                        <CellWithoutGridline content="Kinnistu nimi" />
                        <CellWithoutGridline content={selectedResult.kinnistuNimi} />
                    </TableRow>
                  }
                  <TableRow>
                      <CellWithoutGridline content="Katastritunnus" />
                      <CellWithoutGridline content={selectedResult.katastriNr} />
                  </TableRow>
                  {selectedResult.kvartaliNr && 
                    <TableRow>
                        <CellWithoutGridline content="Kvartal" />
                        <CellWithoutGridline content={selectedResult.kvartaliNr} />
                    </TableRow>
                  }
                  <TableRow>
                      <CellWithoutGridline content="Eraldis" />
                      <CellWithoutGridline content={selectedResult.eraldiseNr} />
                  </TableRow>
                  <TableRow>
                      <CellWithoutGridline content="Pindala" />
                      <CellWithoutGridline content={selectedResult.pindala} />
                  </TableRow>
                <TableRow>
                    <CellWithoutGridline content="Tööliik" />
                    <CellWithoutGridline content={baasandmed['TOOLIIK_TEATIS'][selectedResult.tooKood]} />
                </TableRow>
                {selectedResult.raiutavMaht && 
                    <TableRow>
                        <CellWithoutGridline content="Raiutav maht (tm)" />
                        <CellWithoutGridline content={selectedResult.raiutavMaht} />
                    </TableRow>
                }
                <TableRow>
                    <CellWithoutGridline content="Otsus" />
                    {selectedResult.otsusKinnitatudKp && 
                        <CellWithoutGridline content={selectedResult.otsus} />
                    }
                </TableRow>
                <TableRow>
                    <CellWithoutGridline content="Kinnitatud" />
                    <CellWithoutGridline content={DateUtils.formatTimestampToDate(selectedResult.otsusKinnitatudKp)} />
                </TableRow>
                {selectedResult.otsusePohjendus && <>
                    <TableRow>
                        <CellWithoutGridline content="Otsuse selgitus" colspan={2} />
                    </TableRow>
                    {selectedResult.otsusKinnitatudKp && 
                        <FullWidthCell colspan={2} content={selectedResult.otsusePohjendus} />
                    }
                </>}
                </TableBody>
            </Table>
            </>;
}

export default function TeatisDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { selectedResult, selectedGeometry, detailsOpen, isLoading } = useSelector(state => state.teatis);

    const teatisId = selectedResult?.id;
    const drawerTitle = "Teatis nr " + selectedResult?.teatiseNr;

    const shouldFetch = !teatisId || teatisId !== Number(id);

    useEffect(() => {
        if (shouldFetch) {
          dispatch(fetchTeatisDetails(id));
          dispatch(fetchTeatisGeometry(id));
        } else {
            dispatch(toggleTeatisDetails(true));
        } 
    }, [shouldFetch, id, dispatch]);
    
    const handleClose = () => {
       dispatch(toggleTeatisDetails(false));
    };    
    
    const getGeometry = () => {
        return selectedGeometry ? JSON.parse(selectedGeometry[0].pindGeoJson) : null;
    }

    return  <Details 
                open={detailsOpen} 
                handleClose={handleClose} 
                title={drawerTitle} 
                loading={isLoading} 
                content={<TeatisDetailsTable selectedResult={selectedResult} />} 
                geometry={[getGeometry()]}
                />;

}