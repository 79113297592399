import * as React from 'react';
import { TableCell, TableRow } from '@mui/material';

const FullWidthCell = ({content, colspan}) => {

  return <TableRow>
    <TableCell colSpan={colspan} sx={{borderBottom: 'none', paddingTop: 0, paddingBottom: '16px', fontSize: '1rem'}}>
            {content}
          </TableCell>
    </TableRow>;
};

export default FullWidthCell;