import { Button } from "@mui/material";
import { styled } from "@mui/system";
import Colors from "../style/Colors";

const ClearButton = styled(Button)(({
    marginLeft: "2rem",
    border: '2px solid',
    borderRadius: "2rem",
    minWidth: 92,
    borderColor: Colors.blackCoral,
    color: Colors.blackCoral,
    fontWeight: 'bold',
    '&:hover': {
        border: '2px solid',
        borderColor: Colors.blackCoral,
        backgroundColor: Colors.blackCoral0,
        color: Colors.blackCoral,
    },
}));

export default ClearButton;