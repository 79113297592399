import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { LocationOnOutlined, ArrowForwardIosSharp as ExpandIcon, FormatListBulleted } from "@mui/icons-material";
import StoreUtils from "../../utils/StoreUtils"
import { useDispatch, useSelector } from 'react-redux';
import { toggleSearchDrawer } from '../../stores/search';
import { useNavigate } from 'react-router-dom';
import { fetchKatastriGeom } from '../../stores/map/map';
import { clearFeatures } from '../../stores/map/feature';

const ResultSummary = styled(AccordionSummary)(({
  flexDirection:'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      boxShadow:'0 !important',
  },
}));

const ResultAccordion = styled(Accordion)(({
    '& .MuiPaper-root': {
        boxShadow:'0 !important',
    },
}));

const SubTreeItems = ({ results, defaultExpanded, getSubTreeInfo, openDetails, showCadastre, showGeometries, toggleExpanded, expandedResults }) => {
    const mobile = useSelector(state => state.global.mobileView);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const res = getSubTreeInfo(results);

    const showMap = (res, object) => {
        if (!object) {
            if (!showCadastre && res.items.length > 0) {
                showGeometries(res.items);
            } else {
                dispatch(clearFeatures());
                dispatch(fetchKatastriGeom(results.nimi));
            }
        } else {
            showGeometries([res], object, );
        }
        if (mobile) {
            dispatch(toggleSearchDrawer(false));
            navigate(`/kaart`);
          }
    };

    return <Box sx={{display: 'flex', justifyContent: 'end', background: 'white', alignItems: "center"}}>
        <ResultAccordion defaultExpanded={defaultExpanded || expandedResults.indexOf(results.nimi) > -1} 
                            onChange={() => toggleExpanded(results.nimi)}
                            disableGutters 
                            sx={{width: '95%', background: '#F9F9F9'}}>
            <ResultSummary expandIcon={res.items.length? <ExpandIcon sx={{fontSize: '0.9rem'}}/> : null} key={results.nimi} items={res.items}>
                <Box sx={{display: 'flex', flex: 1}}>
                    <Typography sx={{marginLeft:1}}>{results.nimi}</Typography>
                </Box>
                <Box onClick={(e) => e.stopPropagation()} sx={{display: 'flex'}}>
                    <LocationOnOutlined onClick={() => showMap(res)}/>
                </Box>
            </ResultSummary>
            {res.items.map((r, index) => <React.Fragment key={index}>
                <AccordionDetails sx={{
                    my: 0.2,
                    py: 0.5,
                    background: 'white',
                    borderLeft: 5,
                    borderLeftColor: StoreUtils.getLeftBorderColor(r.color)
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: 'white',
                        alignItems: "center",
                        py: 0.5
                    }}>
                        <Typography>{r.nimi}</Typography>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <LocationOnOutlined onClick={() => showMap(r, true)}/>
                            <FormatListBulleted onClick={() => openDetails(r.id, r.geometry)}/>
                        </Stack>
                    </Box>
                </AccordionDetails>
            </React.Fragment>)}
        </ResultAccordion>
    </Box>
};

export default SubTreeItems;