import React, { useEffect } from 'react';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DateUtils from '../../utils/DateUtils';
import CellWithoutGridline from '../table/CellWithoutGridline';
import Details from '../../pages/Details';
import { fetchMkeDetails, toggleMkeDetails } from '../../stores/mke';
import TableWithBorders from '../table/TableWithBorders';
import TableHeadCell from '../table/TableHeadCell';
import TableRowOverview from '../table/TableRowOverview';
import TableCellOverview from '../table/TableCellOverview';
import OpenAccordionIcon from '../table/OpenAccordionIcon';
import GeneralUtils from '../../utils/GeneralUtils';
import FullWidthCell from '../table/FullWidthCell';

function getConditions(selectedResult) {
    return<>
        <div>
            {selectedResult.lisad}
        </div>
        {selectedResult.showAvalikustus && <div>{selectedResult.metsakaitseAbinoud}</div>}
    </>;
}

function Row(row) {
    const { baasandmed } = useSelector(state => state.classifier);
    const [open, setOpen] = React.useState(false);

    const injuryCause = [baasandmed['KAHJUSTUS_MKE'][row.row.kahjustusePohjus1], 
                            baasandmed['KAHJUSTUS_MKE'][row.row.kahjustusePohjus2], 
                            baasandmed['KAHJUSTUS_MKE'][row.row.kahjustusePohjus3], 
                            baasandmed['KAHJUSTUS_MKE'][row.row.kahjustusePohjus4]].filter(Boolean).join(", ");

    return (
      <React.Fragment>
        <TableRowOverview sx={{ '& > *': { borderBottom: 'unset' } }} content={<>
          <TableCellOverview content={<OpenAccordionIcon handleClick={() => setOpen(!open)} open={open} />} />
          <TableCellOverview component="th" scope="row" content={row.row.valitoo.katastriNr} />
          <TableCellOverview component="th" scope="row" content={row.row.valitoo.kvartaliNr} />
          <TableCellOverview component="th" scope="row" content={row.row.valitoo.eraldiseNr} />
        </>} />
        <TableRow>
          <TableCell style={{ padding: '0px 2px' }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: '4px 0px' }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                        <CellWithoutGridline content="Kahjustatud ala PL" />
                        <CellWithoutGridline content={baasandmed['PUULIIK'][row.row.kahjustatudPuuliik]} />
                    </TableRow>
                    <TableRow>
                        <CellWithoutGridline content="Kahjustuse %" />
                        <CellWithoutGridline content={row.row.kahjustuseProtsent} />
                    </TableRow>
                    <TableRow>
                        <CellWithoutGridline content="Kahjustajad" />
                        <CellWithoutGridline content={injuryCause} />
                    </TableRow>
                    <TableRow>
                        <CellWithoutGridline content="Soovitatava raie liik" />
                        <CellWithoutGridline content={baasandmed['TOOLIIK'][row.row.tooKood]} />
                    </TableRow>
                    <TableRow>
                        <CellWithoutGridline content="Raie pindala" />
                        <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(row.row.soovitatavRaiePindala, 'ha')} />
                    </TableRow>
                    {row.row.maht &&
                      <TableRow>
                        <CellWithoutGridline content="Maht" />
                        <CellWithoutGridline content={GeneralUtils.getFieldWithUnit(row.row.maht, <>m{<sup>3</sup>}</>)} />
                      </TableRow> 
                    }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

function MkeDetailsTable() {
    const { selectedResult } = useSelector(state => state.mke);

    return  <>       
            <Table>
              <TableBody>
                <TableRow>
                    <CellWithoutGridline content="Otsuse kp" />
                    <CellWithoutGridline content={DateUtils.formatTimestampToDate(selectedResult.kinnitamiseKp)} />
                </TableRow>
                <TableRow>
                    <CellWithoutGridline content="Maakond" />
                    <CellWithoutGridline content={selectedResult.maakond} />
                </TableRow>
                <TableRow>
                    <CellWithoutGridline content="Vald" />
                    <CellWithoutGridline content={selectedResult.vald} />
                </TableRow>
                {selectedResult.metskond &&
                  <TableRow>
                      <CellWithoutGridline content="Metskond" />
                      <CellWithoutGridline content={selectedResult.metskond} />
                  </TableRow>
                }
                {selectedResult.kinnistuNr &&
                  <TableRow>
                      <CellWithoutGridline content="Kinnistu nr" />
                      <CellWithoutGridline content={selectedResult.kinnistuNr} />
                  </TableRow>
                }
                {selectedResult.kinnistuNimi &&
                  <TableRow>
                      <CellWithoutGridline content="Kinnistu nimi" />
                      <CellWithoutGridline content={selectedResult.kinnistuNimi} />
                  </TableRow>
                }
              </TableBody>
            </Table>
            <Box mx={'16px'} >
              <TableWithBorders content={<>
                  <TableHead>
                      <TableRow>
                          <TableCell></TableCell>
                          <TableHeadCell content="Katastriüksus" />
                          <TableHeadCell content="Kvartal" />
                          <TableHeadCell content="Eraldis" />
                      </TableRow>
                  </TableHead>                               
                  <TableBody>
                      {selectedResult.read.map((rida) => (
                          <Row key={rida.id} row={rida} />
                      ))}
                  </TableBody>
                  </>
                  } />
            </Box>
            <Table>
              <TableBody>
                <TableRow>
                    <CellWithoutGridline content="Metsa seisukorra hinnang" />
                    </TableRow>
                    {selectedResult.hinnangSeisundile &&                    
                      <FullWidthCell content={selectedResult.hinnangSeisundile} />
                    }
                    <TableRow>
                        <CellWithoutGridline content="Tingimused ja soovitused" />
                    </TableRow>
                    {((selectedResult.showAvalikustus && selectedResult.metsakaitseAbinoud) || selectedResult.lisad)  &&                    
                      (<FullWidthCell content={getConditions(selectedResult)} />)
                    }
                    <TableRow>
                        <CellWithoutGridline content="Märkused" />
                    </TableRow>
                    {selectedResult.markused &&                    
                      <FullWidthCell content={selectedResult.markused} />
                    }
              </TableBody>
            </Table>
            </>;
}

export default function MkeDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { selectedResult, detailsOpen, isLoading } = useSelector(state => state.mke);

    const mkeId = selectedResult?.id;
    const drawerTitle = "MKE akt nr " + selectedResult?.aktiNr;

    const shouldFetch = !mkeId || mkeId !== Number(id);

    useEffect(() => {
        if (shouldFetch) {
          dispatch(fetchMkeDetails(id));
        } else {
          dispatch(toggleMkeDetails(true));
        }
    }, [shouldFetch, id, dispatch]);
    
    const handleClose = () => {
       dispatch(toggleMkeDetails(false));
      };
      
    const getGeometries = () => {
        const geometries = [];
        for (var i = 0; i < selectedResult?.read.length; i++) {
            geometries.push(JSON.parse(selectedResult.read[i].valitoo.alaGeoJson));
        }
        return geometries;
    }

    return  <Details 
                open={detailsOpen} 
                handleClose={handleClose} 
                title={drawerTitle} 
                loading={isLoading} 
                content={<MkeDetailsTable selectedResult={selectedResult} />} 
                geometry={getGeometries()}
                />;

}