import MapControlButton from "./MapControlButton";
import {useDispatch, useSelector} from "react-redux";
import {toggleSearchDrawer} from "../../../stores/search";
import {toggleDetailDrawers, toggleLayerDrawer} from "../../../stores/map/map";
import { useNavigate } from "react-router-dom";

const SearchControl = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { searchDrawerOpen } = useSelector(state => state.search);
    const { layerDrawerOpen } = useSelector(state => state.map);
    const eraldisOpen = useSelector(state => state.eraldis.detailsOpen);
    const teatisOpen = useSelector(state => state.teatis.detailsOpen);
    const mkeOpen = useSelector(state => state.mke.detailsOpen);
    const mueOpen = useSelector(state => state.mue.detailsOpen);

    const handleClick = () => {
        if (layerDrawerOpen) {
            dispatch(toggleLayerDrawer());
        }
        dispatch(toggleDetailDrawers(eraldisOpen, teatisOpen, mkeOpen, mueOpen));
        dispatch(toggleSearchDrawer());
        searchDrawerOpen ? navigate(`/`, { replace : true }) : navigate(`/otsi`);
    };
    return <MapControlButton controlName="search"
                             iconName="Search"
                             title="Otsi"
                             controlActive={searchDrawerOpen}
                             onClick={handleClick}
    />
};

export default SearchControl;