import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import { useDispatch, useSelector } from "react-redux";
import { clearFeatures, fetchClickedFeatures, fetchClickedFeaturesExternal } from "../../../stores/map/feature";

const isLayerInRange = (map, layer) => {
  const currentRes = map.getView().getResolution();
  const minRes = layer.getMinResolution();
  const maxRes = layer.getMaxResolution();

  return !((minRes && minRes > currentRes) || (maxRes && maxRes < currentRes));
};

const handleSingleClick = (evt) => {
  const map = evt.map;
  const dispatch = map.dispatch;
  const view = map.getView();
  const viewResolution = view.getResolution();
  const coordinate = evt.coordinate;

  dispatch(clearFeatures());
  let source = null;
  let queryLayers = [];

  map.getLayers().forEach((layer) => {
    dispatch(clearFeatures());
    if (layer.getVisible() && layer.get('queryable') && layer.getSource().getFeatureInfoUrl && isLayerInRange(map, layer)) {
      if (layer.get('isMr')) {
        if (!source) {
          source = layer.getSource();
        }
        queryLayers.push(layer.getSource().getParams()['LAYERS']);
      } else {
        let externalUrl = layer.getSource().getFeatureInfoUrl(
          coordinate,
          viewResolution,
          'EPSG:3301',
          {
            'INFO_FORMAT': 'application/json',
            'FEATURE_COUNT': 100
          }
        );
        dispatch(fetchClickedFeaturesExternal(layer.get('title'), layer.get('layerLayerName'), externalUrl));
      }
    }
  });
  if (queryLayers.length) {
    const layersJoined = queryLayers.join(',');
    const url = source.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      'EPSG:3301',
      {
        'INFO_FORMAT': 'application/json',
        'QUERY_LAYERS': layersJoined,
        'LAYERS': layersJoined,
        'FEATURE_COUNT': 100,
        'STYLES': ''
      }
    );
    dispatch(fetchClickedFeatures(url, evt.originalEvent.ctrlKey));
  }
};

const SelectFeatureControl = ({ onClick }) => {
  const { map } = useContext(MapContext);
  const { selectActive } = useSelector(state => state.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map) return;

    map.dispatch = dispatch;

    return () => {
      map.dispatch = null;
    }
  }, [map, dispatch]);

  useEffect(() => {
    if (!map) return;

    const handleClick = onClick || handleSingleClick;

    if (selectActive) {

      map.on('singleclick', handleClick);
    } else {
      map.un('singleclick', handleClick);
    }
  }, [map, selectActive, onClick]);

  return null;
};

export default SelectFeatureControl;