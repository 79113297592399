import * as React from 'react';
import { styled } from '@mui/system';
import { TableCell } from '@mui/material';
import Colors from '../style/Colors';

const DetailOverview = styled(TableCell)(({
  color: Colors.blackCoral20,
  textAlign: 'center',
  fontSize: '1rem',
  padding: '1px 8px'
}));


const TableCellOverview = ({content}) => {

  return <DetailOverview>
            {content}
          </DetailOverview>;
};

export default TableCellOverview;