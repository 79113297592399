import * as React from 'react';
import { IconButton } from '@mui/material';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from '@mui/icons-material';

const OpenAccordionIcon = ({handleClick, open}) => {

  return  <IconButton sx={{padding: '5px 0px'}}
                      aria-label="expand row"
                      size="small"
                      onClick={handleClick} >
  {open ? <IndeterminateCheckBoxOutlined /> : <AddBoxOutlined />}
</IconButton>;
};

export default OpenAccordionIcon;