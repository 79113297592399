import TextField from "../form/SearchTextField";
import { SearchAutocompleteField } from "../form/SearchAutocompleteField";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SearchForm from "./SearchForm";
import { fetchEraldised, fetchSelectedEraldised, resetAllocationResults, saveSearchValues, toggleExpanded } from "../../stores/eraldis";
import { useNavigate } from "react-router-dom";
import StoreUtils from "../../utils/StoreUtils";

const eraldisDefault = {
    katastriNr: '',
    maakondKood: '',
    maakond: '',
    valdKood: '',
    vald: '',
    yksuseNimi: '',
    kinnistuNr: '',
    kvartaliNr: '',
};

const SearchTextField = (props) => {
    return <TextField {...props} />
};

export default function EraldisForm () {
    const { counties, municipalities } = useSelector(state => state.classifier);
    const { result, isLoading, searchValues, expandedResults } = useSelector(state => state.eraldis);
    const [county, setCounty] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { handleSubmit, reset, control, formState: { errors, isSubmitSuccessful }, setValue, getValues, register } = useForm({
        defaultValues: (searchValues ?? eraldisDefault),
        resetOptions: {
            keepDirtyValues: true,
        },
    });

    const fetchResults = (data) => {
        const toParams = Object.fromEntries(Object.entries(data).filter(([_, v]) => v.length !== 0 && !!v));
        dispatch(saveSearchValues(form.getValues()));
        dispatch(fetchEraldised(toParams));
    };

    const resetResults = () => {
        dispatch(resetAllocationResults());
    };
    
    const hasEraldised = (result) => {
        for (let i = 0; i < result.alamYksused.length; i++) {
            if (result.alamYksused[i].eraldised.length > 0) {
                return true;
            }
        }
        return false;
    };

    const showSelectedEraldised = (result, object) => {
        const ids = [];
        if (result.alamYksused) {
            for (let i = 0; i < result.alamYksused.length; i++) {
                result.alamYksused[i].eraldised?.forEach(e => ids.push(e.id));
            }
        } else {
            const selectedIds = result.map(r => r.id);
            ids.push(...selectedIds);
        }
        dispatch(fetchSelectedEraldised(ids, object));
    };

    const getSubTreeInfo = (results) => {
        return { items: results.eraldised.map(result => ({id: result.id, nimi: result.eraldiseNr, geometry: result.alaGeoJson ?? null, color: StoreUtils.getEraldisStaatusColor(result)})), };
    };

    const openDetails = (id) => {
        navigate(`/eraldis/${id}`);
    };

    const toggleResults = (result) => {
        dispatch(toggleExpanded(result));
    };
    
    const form = { handleSubmit:handleSubmit, reset:reset, control:control, formState: { errors:errors, isSubmitSuccessful:isSubmitSuccessful }, setValue:setValue, defaultValues:eraldisDefault, getValues:getValues, register:register};

    const EraldisFields = ({setValue, control, errors, getValues}) => {          
        const filteredMunicipalities = municipalities.filter(muni => !!county? muni.maakondKood === county : getValues().maakondKood === muni.maakondKood); 
        const disabled = !filteredMunicipalities.length;

        return <>
            <SearchTextField name={"katastriNr"} label="Katastriüksus" pattern='cadastre' errors={errors} control={control} />
            <SearchAutocompleteField name="maakondKood" label="Maakond" options={counties} setValue={setValue} setCounty={setCounty} control={control}/>
            <SearchAutocompleteField name="valdKood" label="Vald" options={filteredMunicipalities} disabled={disabled} setValue={setValue} control={control}/>
            <SearchTextField name="yksuseNimi" label="Kinnistu nimi" control={control} />
            <SearchTextField name="kinnistuNr" label="Kinnistu number" control={control} pattern='number' maxLength={10} errors={errors} />
            <SearchTextField name="kvartaliNr" label="Kvartali number" control={control} />
        </>
    };

    return <SearchForm fields={EraldisFields({setValue:setValue, control:control, getValues:getValues, errors:errors })}
                form={form}    
                isLoading={isLoading}  
                result={result}     
                handleSubmit={handleSubmit}
                fetchResults={fetchResults}
                resetResults={resetResults}
                getSubTreeInfo={getSubTreeInfo}
                openDetails={openDetails}
                showLocationForMain={hasEraldised}
                showGeometries={showSelectedEraldised}
                expandedResults={expandedResults}
                toggleExpanded={toggleResults}
                />;
};
