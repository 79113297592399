import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import OLImageLayer from "ol/layer/Image";
import OLImageWms from "ol/source/ImageWMS";
import MapUtils from "../../../utils/MapUtils";

const ImageWMSLayer = ({ url, layers, zIndex = 0, queryable = false, title, layerLayerName, styles,
	geometryName, groupedLayers, cqlFilter, wmsVersion, sldBody, isMr, maxRes }) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let olImageWms;
		let params = {
			"VERSION": '1.1.1',
			"STYLES": '',
			"CQL_FILTER": cqlFilter,
			"ENV": '',
			"VIEWPARAMS": ''
		}
		if (styles !== undefined && styles.length > 0) {
			params.ENV = MapUtils.getViewparams(styles);
		}
		if (sldBody !== undefined && sldBody.length > 0) {
			params.SLD_BODY = sldBody;
		} else {
			params.LAYERS = layers;
		}

		olImageWms = new OLImageWms({
			url: url,
			crossorigin: "Anonymous",
			params: params,
			serverType: "geoserver"
		});

		let wmsLayer = new OLImageLayer({
			source: olImageWms,
			zIndex,
			queryable,
			maxResolution: maxRes || 'Infinity'
		});
		wmsLayer.set('title', title);
		wmsLayer.set('layerLayerName', layerLayerName);
		wmsLayer.set('geometryName', geometryName);
		wmsLayer.set('groupedLayers', groupedLayers);
		wmsLayer.set('isMr', isMr);

		map.addLayer(wmsLayer);

		return () => {
			if (map) {
				map.removeLayer(wmsLayer);
			}
		};
	}, [map, url, layers, zIndex, queryable, title, layerLayerName, styles, geometryName, groupedLayers,
		cqlFilter, wmsVersion, sldBody, isMr, maxRes]);

	return null;
};

export default ImageWMSLayer;