import { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import { useSelector } from "react-redux";
import Draw from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import { unByKey } from "ol/Observable";
import { Overlay } from "ol";
import MapControlButton from "./MapControlButton";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { GeometryType } from "../../../constants/mapConstants";
import LayerStyles from "../LayerStyles";
import MapUtils from "../../../utils/MapUtils";
import GeneralUtils from "../../../utils/GeneralUtils";

/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
let measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
let measureTooltip;

const source = new VectorSource();

const vector = new VectorLayer({
  source: source,
  zIndex: 1001,
  style: LayerStyles.measure
});

let draw; // global so we can remove it later
/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const formatLength = function (line) {
  const length = line.getLength();
  let output;
  if (length > 100) {
    output = GeneralUtils.formatNumber(Math.round((length / 1000) * 100) / 100) + ' km';
  } else {
    output = GeneralUtils.formatNumber(Math.round(length * 100) / 100) + ' m';
  }
  return output;
};

const formatAreaPolygon = (polygon) => MapUtils.formatArea(polygon.getArea());

function addInteraction(map, type) {
  let sketch;

  draw = new Draw({
    source: source,
    type: type,
    style: LayerStyles.selectingMeasuring
  });
  map.addInteraction(draw);

  createMeasureTooltip(map);

  let listener;
  draw.on('drawstart', function (evt) {
    // set sketch
    sketch = evt.feature;

    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
    let tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on('change', function (evt) {
      const geom = evt.target;
      let output;
      if (geom instanceof Polygon) {
        output = formatAreaPolygon(geom);
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (geom instanceof LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on('drawend', function () {
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
    measureTooltip.setOffset([0, -7]);
    // unset sketch
    sketch = null;
    // unset tooltip so that a new one can be created
    measureTooltipElement = null;
    createMeasureTooltip(map);
    unByKey(listener);
  });
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip(map) {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
    stopEvent: false,
    insertFirst: false,
  });
  map.addOverlay(measureTooltip);
}

const MeasureControl = () => {
  const controlName = 'measure';
  const { map } = useContext(MapContext);
  const { activeControl } = useSelector(state => state.map);
  const [type, setType] = useState(GeometryType.LINE_STRING);

  const isActive = activeControl === controlName;

  useEffect(() => {
    if (!map || !type) return;

    if (isActive) {
      map.addLayer(vector);
      addInteraction(map, type);
    } else {
      vector.getSource().clear();
      map.getOverlays().clear();
    }

    return () => {
      map.removeLayer(vector);
      map.removeInteraction(draw);
    };
  }, [map, type, isActive]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return <MapControlButton controlName={controlName} iconName="Straighten" title="Mõõda">
    <div className="ol-measure-panel">
      <div>Mõõtmine</div>

      <RadioGroup aria-label="measure tool type radio buttons" value={type} onChange={handleChange}>
        <FormControlLabel value={GeometryType.LINE_STRING} control={<Radio />} label='Vahemaa' />
        <FormControlLabel value={GeometryType.POLYGON} control={<Radio />} label='Pindala' />
      </RadioGroup>
    </div>
  </MapControlButton>;
};

export default MeasureControl;