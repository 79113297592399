import { formatISO } from "date-fns";
import i18next from "i18next";
import { showWarnings } from "../stores/notification";

const StoreUtils = {
  handleError: (error, hasError) => async dispatch => {
    console.log(error);
    const response = error.response;
    let errorMsgs = [];
    switch (response?.status) {
      case 400:
        if (response.data && response.data.length) {
          response.data.forEach(error => {
            if (error.errorCode) {
              let errorMsg = {
                message: error.errorCode,
                parameters: {}
              };
              if (error.fieldName && error.objectType) {
                errorMsg.parameters.objectType = i18next.t(`objectType.${error.objectType}`);
                errorMsg.parameters.field = i18next.t(`error.${error.objectType}.${error.fieldName}`);
              }
              errorMsgs.push(errorMsg);
            } else {
              errorMsgs.push('Tehniline viga');
            }
          });
        } else {
          errorMsgs.push('Tehniline viga');
        }
        break;
      case 403:
        errorMsgs.push('Kasutaja rollil puuduvad selle funktsionaalsuse kasutamise õigused!');
        break;
      case 404:
        errorMsgs.push('Objekti ei leitud');
        break;
      default:
        errorMsgs.push('Tehniline viga');
    }
    !!hasError && dispatch(hasError(true));
    dispatch(showWarnings(errorMsgs));
  },
  setDateInterceptor: (axios) => {
    axios.interceptors.request.use((config) => {
      config.transformRequest = [function (data, headers) {
        if (headers['Content-Type'] === 'application/json' && data && data instanceof Object) {
          Object.keys(data).forEach(key => {
            if (data[key] && data[key] instanceof Date) {
              data[key] = formatISO(data[key], { representation: 'date' });
            }
          });
          return JSON.stringify(data);
        }
        return data;
      }, ...axios.defaults.transformRequest];
      return config;
    });
  },  
  getLeftBorderColor: (leftBorder) => {
    return leftBorder ? leftBorder : 'rgba(0, 0, 0 ,0)';
  },
  getEraldisStaatusColor: (eraldis) => {
    switch (eraldis?.staatus) {
      case 0:
        return 'rgb(255, 255, 0)';
      case 1:
        if (eraldis.etapp === 'AEGUNUD') return 'rgb(128, 128, 128)';
        return 'rgb(0, 115, 229)';
      case 2:
        return 'rgb(190, 104, 190)';
      case 3:
        return 'rgb(132, 83, 33)';
      default:
        return 'white';
    }
  },
  getTeatisStaatusColor: (teatis) => {
    if ((teatis.menetluseLiik === 'T' || teatis.menetluseLiik === 'K') && (teatis.olek === 6 || teatis.olek === 10 || teatis.olek === 12)) {
      return 'rgb(0, 176, 80)';
    } else if (teatis.menetluseLiik === 'L' && (teatis.olek === 6 || teatis.olek === 10 || teatis.olek === 12)) {
      return 'rgb(146, 208, 80)';
    } else {
      return 'rgba(0, 0, 0, 0)';
    }
  }
};

export default StoreUtils;