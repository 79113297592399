import axios from 'axios';
import StoreUtils from './utils/StoreUtils';

const api = axios.create({
    baseURL: '/portaal/api/rest/',
    headers: {
        'Content-Type': 'application/json'
    },
});

const cas = '/portaal/api/cas/login';

StoreUtils.setDateInterceptor(api);

export { api, cas };