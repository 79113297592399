import { Accordion, AccordionDetails, AccordionSummary, ButtonGroup, Grid, IconButton, List, ListItem, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFeatures, setHighlightGeometries, setSelectedInfoObject, toggleFeaturesDialog, toggleMinimizeFeaturesDialog } from '../../stores/map/feature';
import { centerFeatures, storeMinData } from '../../stores/map/map';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/ArrowForward';
import MapUtils from '../../utils/MapUtils';
import { Box } from '@mui/system';
import { ArrowBackIos, Close, ExpandMore, FormatListBulleted, LocationOnOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import StoreUtils from '../../utils/StoreUtils';
import { selectGeometry, toggleMueDetails } from '../../stores/mue';
import Colors from '../style/Colors';
import { toggleEraldisDetails } from '../../stores/eraldis';
import { toggleTeatisDetails } from '../../stores/teatis';
import { fetchMkeFromValitooId, resetSelectedMkeid, toggleMkeDetails } from '../../stores/mke';
import FeaturesDrawer from './FeaturesDrawer';

const bordersDarkColor = '#D2D3D8';

const FeaturesListItem = styled(ListItem)(({
  borderTopWidth: 1,
  borderTopColor: bordersDarkColor,
  borderBottomWidth: 1,
  borderBottomColor: bordersDarkColor,
  borderBottomStyle: 'solid'
}))

function GenericJsonView({ title, rows, handleClose, onViewMap }) {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const row = rows[index];

  useEffect(() => setIndex(0), [rows]);
  useEffect(() => {
    if (row.geometry) {
      dispatch(setHighlightGeometries([row.geometry]))
    }
  }, [row, dispatch]);

  return <FeaturesDrawer open={true} content={<>
      <Grid container alignItems="center" justifyContent="space-between" padding={2} paddingRight={3} spacing={1} maxHeight={'33vh'}>
        <Grid item xs={10} sx={{ fontSize: 20 }}>{title}</Grid>
        <Grid item xs={1}>
          <IconButton aria-label="details" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <IconButton aria-label="previous item" onClick={() => setIndex(index - 1)} disabled={index < 1}>
            <BackIcon />
          </IconButton>
          {index + 1}/{rows.length}
          <IconButton aria-label="next item" onClick={() => setIndex(index + 1)} disabled={index + 1 >= rows.length}>
            <ForwardIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => onViewMap(row.geometry)}><LocationOnOutlined /></IconButton>
        </Grid>
        {Object.keys(row).filter(key => key !== 'id' && key !== 'geometry' && key !== 'mapLayerTitle' && key !== 'mapLayerLayerName').map((key, index) => (
          <React.Fragment key={index}>
            {row[key] && <>
              <Grid item xs={5} lg={5}>
              <b>{MapUtils.prettyfyKey(key)}</b>
            </Grid>
            <Grid item xs={7} lg={7}>
              {row[key]}
            </Grid>
            </>}
          </React.Fragment>
        ))}
      </Grid>
    </>} />;
}

function FeaturesAccordion({ title, rendered, children }) {
  if (!rendered) return <></>;

  return <Accordion disableGutters defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMore sx={{ color: Colors.sapphireBlue}}/>} aria-controls={title} sx={{ color: Colors.sapphireBlue, fontSize: 14, paddingLeft: 5 }}>
      {title}
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0px 16px' }}>
      {children}
    </AccordionDetails>
  </Accordion>;
}

function EraldisedList({ rows, onViewMap }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return <List disablePadding>
    {rows.map((row, index) => <FeaturesListItem key={row.eraldisId}
      sx={{ borderLeftWidth: 5, borderLeftStyle: 'solid', borderLeftColor: StoreUtils.getEraldisStaatusColor(row), borderTopStyle: !index && 'solid' }}
      secondaryAction={
        <Stack direction="row">
          <IconButton edge="end" onClick={() => onViewMap(row.geometry)}><LocationOnOutlined /></IconButton>
          <IconButton edge="end" onClick={() => {navigate(`/eraldis/${row.eraldisId}`); dispatch(toggleEraldisDetails(true));}}><FormatListBulleted /></IconButton>
        </Stack>
      }>
      <Box sx={{ fontWeight: 'bold', marginRight: 1 }}>{row.katastriNr}</Box> nr {row.eraldiseNr}
    </FeaturesListItem>)}
  </List>;
}

function TeatisedList({ rows, onViewMap }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return <List disablePadding>
    {rows.map((row, index) => <FeaturesListItem key={row.teatisId}
      sx={{ borderTopStyle: !index && 'solid' }}
      secondaryAction={
        <Stack direction="row">
          <IconButton edge="end" onClick={() => onViewMap(row.geometry)}><LocationOnOutlined /></IconButton>
          <IconButton edge="end" onClick={() => {navigate(`/teatis/${row.teatisId}`); dispatch(toggleTeatisDetails(true));}}><FormatListBulleted /></IconButton>
        </Stack>
      }>
      <Box sx={{ fontWeight: 'bold' }}>{row.teatiseNr}</Box>, {row.tooKood}
    </FeaturesListItem>)}
  </List>;
}

function ValitoodList({ rows, onViewMap }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedMkeId } = useSelector(state => state.mke);

  useEffect(() => {
    if (selectedMkeId !== null) {
      navigate(`/mke/${selectedMkeId}`);
      dispatch(resetSelectedMkeid());  
    }
  }, [selectedMkeId, navigate, dispatch])

  const handleNavigate = (row) => {
    if (row.liik === 'MKE') {
      dispatch(fetchMkeFromValitooId(row.valitooId));
      dispatch(toggleMkeDetails(true));
    } else {
      dispatch(selectGeometry(row.geometry));
      navigate(`/mue/${row.valitooId}`);
      dispatch(toggleMueDetails(true));
    }
  };

  return <List disablePadding>
    {rows.map((row, index) => <FeaturesListItem key={row.valitooId}
      sx={{ borderTopStyle: !index && 'solid' }}
      secondaryAction={
        <Stack direction="row">
          <IconButton edge="end" onClick={() => onViewMap(row.geometry)}><LocationOnOutlined /></IconButton>
          <IconButton edge="end" onClick={() => handleNavigate(row)}><FormatListBulleted /></IconButton>
        </Stack>
      }>
      <Box sx={{ fontWeight: 'bold', marginRight: 1 }}>{row.liik}</Box> {row.aktiNr ?? row.nr}
    </FeaturesListItem>)}
  </List>;
}

function InfoObjectsView({ objectTypes, handleSelect }) {
  return <List disablePadding>
    {objectTypes.map((objectType, index) => <FeaturesListItem key={index}
      sx={{ borderTopStyle: !index && 'solid' }}
      secondaryAction={
        <IconButton edge="end" aria-label="details" onClick={() => handleSelect(objectType)}>
          <FormatListBulleted />
        </IconButton>
      }>
      <Box sx={{ fontWeight: 'bold', marginRight: 1 }}>{objectType.title}</Box> {objectType.objects.length} {objectType.objects.length === 1 ? 'kirje' : 'kirjet'}
    </FeaturesListItem>)}
  </List>;
}

export default function FeaturesDialog() {
  const dispatch = useDispatch();
  const { featuresDialogOpen, eraldised, teatised, valitood, infoObjectTypes, objects, selectedInfoObject } = useSelector(state => state.feature);

  useEffect(() => {
    dispatch(setHighlightGeometries(!!selectedInfoObject ? [selectedInfoObject.objects[0].geometry] : []));
  }, [selectedInfoObject, dispatch]);

  if (!featuresDialogOpen || !infoObjectTypes || !objects?.length) {
    return <></>;
  }

  const handleInfoClose = () => {
    dispatch(setSelectedInfoObject());
    if (objects.length === 1) {
      dispatch(clearFeatures());
      dispatch(toggleFeaturesDialog(false));
    }
  };

  const handleSelectInfo = (ot) => {
    dispatch(setSelectedInfoObject(ot));
  };

  const handleViewMap = (geometry) => {
    dispatch(setHighlightGeometries([geometry]));
    dispatch(centerFeatures([MapUtils.geometryToOLFeature(geometry)], 500));
  };

  if (selectedInfoObject) {
    return <GenericJsonView
      title={selectedInfoObject.title}
      rows={selectedInfoObject.objects}
      handleClose={handleInfoClose}
      onViewMap={handleViewMap}
    />;
  }

  const drawerTitle = "Valitud objektid";

  return <FeaturesDrawer open={featuresDialogOpen} content={<>
    <Box maxHeight={'33vh'} pb={2} >
      <Box sx={{ paddingLeft: 2, paddingTop: 1, paddingBottom: 0, fontSize: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        {drawerTitle}
        <ButtonGroup sx={{marginRight: '8px'}}>
          <IconButton edge="end" onClick={() => {dispatch(toggleMinimizeFeaturesDialog(false)); dispatch(storeMinData({title: drawerTitle, features: true})); }} aria-label="minimize">                          
            <ArrowBackIos />
          </IconButton>
          <IconButton onClick={() => {dispatch(toggleFeaturesDialog(false)); dispatch(clearFeatures()); }} aria-label="minimize">                          
            <Close />
          </IconButton>
      </ButtonGroup>
      </Box>
      <FeaturesAccordion title="Eraldised" rendered={!!eraldised?.length}>
        <EraldisedList rows={eraldised} onViewMap={handleViewMap} />
      </FeaturesAccordion>
      <FeaturesAccordion title="Teatised" rendered={!!teatised?.length}>
        <TeatisedList rows={teatised} onViewMap={handleViewMap} />
      </FeaturesAccordion>
      <FeaturesAccordion title="Välitööd" rendered={!!valitood?.length}>
        <ValitoodList rows={valitood} onViewMap={handleViewMap} />
      </FeaturesAccordion>
      <FeaturesAccordion title="Info" rendered={!!infoObjectTypes?.length}>
        <InfoObjectsView objectTypes={infoObjectTypes} handleSelect={handleSelectInfo} />
      </FeaturesAccordion>
    </Box>
    </>} />;
}